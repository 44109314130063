import { useEffect, useState } from "react"

import {
  useForm,
  usePayloadFormatters,
  useStepTransition
} from "@garantidos/hooks"
import { resetFocus } from "@garantidos/utils/js/accessibility"

import validatorsDictionary from "pages/Proposal/hooks/validators"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { fields } from "./fields"
import * as trackers from "./trackings"

const useSms = () => {
  const [isValid, setIsValid] = useState(false)
  const { form } = useForm({
    fields,
    context: ProposalContext,
    validatorsDictionary
  })

  const {
    proposalData,
    cpfData,
    fetchProposalData,
    sendProposalData,
    errorMessage,
    setErrorMessage,
    errorHandler,
    isLoading,
    setIsLoading,
    stepInfo,
    setIsStepVisible
  } = useProposalContext()
  const { stepNavigator } = useStepTransition(setIsStepVisible)
  const { formatSendPayload } = usePayloadFormatters(fields)
  const { previousStep, nextStep } = stepInfo
  const { hashedCpf } = cpfData

  const onSubmitCallback = async (formData) => {
    try {
      const payload = formatSendPayload(formData)
      await sendProposalData({ payload, step: "sms-confirmation" })
      stepNavigator("next", nextStep)
    } catch (error) {
      error.message = error.requestMessage
      errorHandler(error)
      trackers.alert(hashedCpf, error)
    }
  }

  const handlePrevStep = async () => {
    if (isLoading) return
    try {
      await fetchProposalData({ step: previousStep, recaptcha: true })
      trackers.previousGA4(hashedCpf)
      stepNavigator("prev", previousStep)
    } catch (error) {
      errorHandler(error)
    }
  }

  useEffect(() => {
    trackers.pageview(hashedCpf)
    resetFocus()
  }, [])

  const onFormSubmit = () => {
    if (isLoading) return
    window.dispatchEvent(
      new CustomEvent("onOTPSubmitClick" + proposalData.sms_auth_token)
    )
    setIsLoading(true)
  }

  const onOtpInvalid = () => {
    setIsLoading(false)
  }

  return {
    onSubmitCallback,
    handlePrevStep,
    form,
    errorMessage,
    setErrorMessage,
    isLoading,
    onFormSubmit,
    onOtpInvalid,
    telephone: proposalData.proponent_phone || proposalData.phone,
    otpToken: proposalData.sms_auth_token,
    isValid,
    setIsValid
  }
}

export default useSms
