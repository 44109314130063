import { useProposalContext } from "contexts/proposal"

const checkRetired = (value) => value === "APOSENTADO"

const useFlags = () => {
  const { proposalData } = useProposalContext()

  const isMarried = proposalData.marital_status === "CASADO"

  const flags = {
    isMarried,
    isShareIncome: !!proposalData.share_income,
    hasPartner: !!proposalData.share_income || isMarried,
    isStableUnion: !!proposalData.stable_union,
    hasReferral: !!proposalData.has_referral,
    isSameAddress: !!proposalData.same_address,
    isLeadRetired: checkRetired(proposalData.occupation_category),
    isPartnerRetired: checkRetired(proposalData.partner_occupation_category),
    hasAccount: !!proposalData.is_account_holder
  }

  return flags
}

export { useFlags }
