import { createContext, useState } from "react"

import { ENVIRONMENT } from "gatsby-env-variables"

import { proposalContextApi } from "./proposal"

const ProposalContext = createContext()

const getProposalContext = ({ stepsData }) => {
  const ProposalDataProvider = ({ children, initialStepData = {} }) => {
    const initialStepInfo = {
      currentFlow: initialStepData.currentFlow ?? "default",
      currentStep: initialStepData.currentStep ?? "softlead",
      previousStep: initialStepData.previousStep ?? "softlead",
      nextStep: initialStepData.nextStep ?? "simulation",
    }

    const [proposalData, setProposalData] = useState({})
    const [cpfData, setCpfData] = useState({ cpf: "", hashedCpf: "" })
    const [stepInfo, setStepInfo] = useState(initialStepInfo)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoaderComponentVisible, setIsLoaderComponentVisible] =
      useState(false)
    const [isStepVisible, setIsStepVisible] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")
    const [errorFields, setErrorFields] = useState({})

    if (ENVIRONMENT !== "production") {
      console.log(
        "%c ============ [Current Context] ============",
        "color: green",
      )
      console.log({ stepInfo, proposalData, cpfData })
    }

    const {
      fetchProposalData,
      sendProposalData,
      checkFinanceExpense,
      checkFinanceIOF,
      fetchDeadlines,
      fetchAsset,
      resendToken,
      updateProposalData,
      updateStepInfo,
      errorHandler,
      checkHashedCpf,
      updateCpfData,
      retrieveProposal,
      retrieveProposalRemarketing,
    } = proposalContextApi({
      proposalData,
      setProposalData,
      stepInfo,
      setStepInfo,
      setIsLoading,
      stepsData,
      setErrorMessage,
      setErrorFields,
      cpfData,
      setCpfData,
    })

    return (
      <ProposalContext.Provider
        value={{
          stepInfo,
          proposalData,
          fetchProposalData,
          sendProposalData,
          checkFinanceExpense,
          checkFinanceIOF,
          resendToken,
          fetchDeadlines,
          fetchAsset,
          updateProposalData,
          updateStepInfo,
          isLoading,
          isLoaderComponentVisible,
          setIsLoaderComponentVisible,
          setIsLoading,
          errorMessage,
          setErrorMessage,
          errorFields,
          setErrorFields,
          errorHandler,
          cpfData,
          checkHashedCpf,
          updateCpfData,
          retrieveProposal,
          retrieveProposalRemarketing,
          isStepVisible,
          setIsStepVisible,
        }}
      >
        {children}
      </ProposalContext.Provider>
    )
  }

  return { ProposalContext, ProposalDataProvider }
}

export default getProposalContext
