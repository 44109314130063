import {
  MARITAL_STATUS_OPTIONS,
  NATIONALITY_OPTIONS,
  OCCUPATION_CATEGORY_OPTIONS
} from "pages/Proposal/constants/dropdownOptions"

export const fields = {
  nationality: {
    type: "select",
    name: "nationality",
    label: "nacionalidade",
    placeholder: "selecione",
    options: NATIONALITY_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select",
    defaultValue: "BRASILEIRA"
  },
  occupationCategory: {
    type: "select",
    name: "occupation_category",
    label: "tipo de renda",
    placeholder: "selecione",
    options: OCCUPATION_CATEGORY_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select"
  },
  monthlyIncome: {
    type: "currency",
    name: "monthly_income",
    label: "renda total mensal",
    rules: {
      greaterThanZero: true,
      maxIncome: 1_000_000
    }
  },
  maritalStatus: {
    type: "select",
    name: "marital_status",
    label: "estado civil",
    placeholder: "selecione",
    options: MARITAL_STATUS_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select"
  },
  stableUnion: {
    type: "radio",
    name: "stable_union",
    options: [
      {
        id: "stable_union2",
        value: "nao",
        label: <span aria-hidden={true}>não</span>,
        ariaLabel: "não convivo em união estável"
      },
      {
        id: "stable_union1",
        value: "sim",
        label: <span aria-hidden={true}>sim</span>,
        ariaLabel: "sim, convivo em união estável"
      }
    ],
    sendFormat: "bool",
    fetchFormat: "bool"
  }
}
