import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import useCardAccountNotHolder from "./hooks"

import "./style.scss"

const CardAccountNotHolder = () => {
  const { handleTrackEvent } = useCardAccountNotHolder()

  return (
    <div className="cardAccountNotHolder">
      <Text as="p" className="cardAccountNotHolder__text">
        Guarde o número da sua proposta e acompanhe a análise do seu crédito.
        Adiante os documentos necessários para a sua contratação clicando no
        botão abaixo:
      </Text>
      <Button
        className="cardAccountNotHolder__button"
        tagName="a"
        href="https://www.itau.com.br/emprestimos-financiamentos/credito-imobiliario/acompanhar-proposta"
        target="_blank"
        rel="noreferrer"
        fluid
        onClick={handleTrackEvent}
      >
        acompanhar proposta
      </Button>
    </div>
  )
}

export default CardAccountNotHolder
