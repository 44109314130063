const aboutAmortizationData = [
  {
    content: [
      "É a forma que as suas parcelas serão calculadas e nós te oferecemos duas opções:"
    ]
  },
  {
    title: "SAC (Sistema de Amortização Constante)",
    content: [
      "Onde o valor a ser abatido do saldo devedor é constante e os juros são calculados sob o saldo devedor atualizado de cada mês, que tende a diminuir mais rápido."
    ]
  },
  {
    title: "MIX (Sistema Misto de Amortização)",
    content: [
      "Onde você começa com uma parcela maior nos primeiros meses, pois você paga mais juros nessas parcelas com amortização crescente e ela diminui gradualmente a partir do 37º mês, passando a ter uma amortização constante."
    ]
  }
]

export default aboutAmortizationData
