import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"
import IconSetaBaixo from "@mobi/ds/static/icons/outlined/seta-baixo.svg"

import useDetailsList from "./hooks"

import "./style.scss"

import { detailListData } from "./data"

const DetailsList = ({ fieldsToShow }) => {
  const {
    isMobile,
    toggleDetailsList,
    isExpanded,
    detailsListExpansionClass,
    buttonIconExpansionClass,
    buttonProps
  } = useDetailsList(fieldsToShow)

  const detailSimulation = detailListData(fieldsToShow)

  return (
    <div
      className="detailsList"
      data-aos="fade"
      data-aos-delay="100"
      data-aos-duration="500"
    >
      <div className="detailsList__container">
        <ul
          className={`detailsList__list ${detailsListExpansionClass}`}
          aria-label="Lista com resumo de valores e detalhes sobre a simulação"
          id="detailsList"
        >
          {detailSimulation.map(({ label, value }, index) => (
            <li
              key={index}
              className="detailsList__listItem"
              aria-hidden={isMobile && !isExpanded && index > 1}
            >
              <Text as="p" className="detailsList__label">
                {label}
              </Text>
              <Text as="p" className="detailsList__value">
                {value}
              </Text>
            </li>
          ))}
        </ul>
        <If
          condition={isMobile}
          renderIf={
            <Button
              variant="action"
              className="detailsList__button"
              onClick={toggleDetailsList}
              {...buttonProps}
            >
              <IconSetaBaixo
                className={`detailsList__icon ${buttonIconExpansionClass}`}
              />
            </Button>
          }
        />
      </div>
    </div>
  )
}

export default DetailsList
