import { NextSteps } from "@garantidos/components"

import If from "@mobi/components/If"

import AccompanyCard from "./components/AccompanyCard"
import FinishedComplementaryText from "./components/FinishedComplementaryText"
import FinishedContent from "./components/FinishedContent"
import FinishedMainTitle from "./components/FinishedMainTitle"

import useFinished from "./hooks"

import "./style.scss"

import data from "./data"

const Finished = () => {
  const {
    isMobile,
    proposalIntegrationNumber,
    hasAccount,
    handleNextStepTrack
  } = useFinished()
  return (
    <div className="proposalFinished">
      <div className="proposalFinished__header">
        <div className="innerContainer proposalFinished__headerContainer">
          <div className="proposalFinished__contentContainer">
            <FinishedMainTitle
              proposalIntegrationNumber={proposalIntegrationNumber}
            />
            <div className="proposalFinished__divider" />
            <FinishedComplementaryText isAccountHolder={hasAccount} />
          </div>
          <If
            condition={!isMobile}
            renderIf={
              <div className="proposalFinished__cardContainer">
                <AccompanyCard isAccountHolder={hasAccount} />
              </div>
            }
          />
        </div>
      </div>
      <div className="innerContainer proposalFinished__finishContainer">
        <div className="proposalFinished__contentContainer">
          <NextSteps
            blok={data}
            className="proposalFinished__nextSteps"
            callback={handleNextStepTrack}
          />
          <FinishedContent />
          <If
            condition={isMobile}
            renderIf={
              <div className="proposalFinished__cardContainer">
                <AccompanyCard isAccountHolder={hasAccount} />
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Finished
