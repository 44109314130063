import AriaLabel from "@mobi/components/AriaLabel"
import Text from "@mobi/ds/components/Text"

import "./style.scss"

import data from "./data"

const CardFooter = () => (
  <div className="cardFooter">
    <Text as="p" className="cardFooter__text">
      Você também pode entrar em contato com o Itaú Imobfone:
    </Text>
    {data?.map((phone) => (
      <AriaLabel ariaText={`${phone.boldText}: ${phone.text}`}>
        <Text as="p" className="cardFooter__text--strong" aria-hidden="true">
          {phone.boldText}
        </Text>
        <Text as="p" className="cardFooter__text" aria-hidden="true">
          {phone.text}
        </Text>
      </AriaLabel>
    ))}
  </div>
)

export default CardFooter
