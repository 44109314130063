import { MarketplaceCards } from "@garantidos/components"

import Text from "@mobi/ds/components/Text"

import "./style.scss"

import * as trackers from "../../trackings"
import LikertComponent from "../LikertComponent/index"

const FinishedContent = () => {
  const marketplaceCallback = (name) => {
    trackers.marketplace(name)
  }

  return (
    <div className="innerContainer finishedContent">
      <LikertComponent />
      <Text as="h2" className="finishedContent__title">
        achamos que pode te interessar
      </Text>
      <MarketplaceCards app="imob" marketplaceCallback={marketplaceCallback} />
    </div>
  )
}

export default FinishedContent
