import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = { label: "IT:NL:NCC:CredImob:Simulador:NosConhecendo", hashedCpf }
  const ga4 = {
    customPath: "/credito/imob/isolado/contratacao/simulacao/inicio-simulacao",
    implementationTeam: "imob:v01"
  }

  const segment = "Simulation Page Viewed"

  trackPageview({ ga, ga4, segment })
}

const advance = () => {
  const segment = "Softlead Sent"

  trackEvent({
    segment
  })
}

const cpfCollect = (hashedCpf) => {
  if (!hashedCpf) return
  const ga = {
    label: "BTN:Simulador:ColetaCPF",
    hashedCpf
  }

  trackEvent({ ga })
}

const backHome = (hashedCpf) => {
  const ga4 = {
    customPath: "/credito/imob/isolado/contratacao/simulacao/inicio-simulacao",
    hashedCpf,
    implementationTeam: "imob:v01",
    detail: "btn:voltar-inicio",
    eventName: "click"
  }
  trackEvent({ ga4 })
}

const advanceGA4 = () => {
  const ga4 = {
    customPath: "/credito/imob/isolado/contratacao/simulacao/inicio-simulacao",
    implementationTeam: "imob:v01",
    detail: "btn:avancar",
    eventName: "click",
    isSoftLead: true
  }

  trackEvent({ ga4 })
}

const alert = (hashedCpf, error) => {
  const ga4 = {
    customPath: "/credito/imob/isolado/contratacao/simulacao/inicio-simulacao",
    hashedCpf,
    implementationTeam: "imob:v01",
    eventName: "alert",
    description: "erro",
    detail: error.message
      ?.toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replaceAll(/ /g, "-")
      .replaceAll(/!/g, "")
  }

  trackEvent({ ga4 })
}

export { advance, pageview, backHome, cpfCollect, alert, advanceGA4 }
