import { useEffect, useState } from "react"

import { proposalHttpController } from "@garantidos/services"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

import AboutAmortization from "./components/AboutAmortization"
import AboutCreditLines from "./components/AboutCreditLines"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "../../trackings"
import { fields } from "./fields"

const useFormGroupCreditLine = ({ form }) => {
  const [modalShow, setModalShow] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [feeTypeDisabled, setFeeTypeDisabled] = useState(false)
  const [amortizationDisabled, setAmortizationDisabled] = useState(false)
  const [financingTermRuleViolation, setFinancingTermRuleViolation] =
    useState(false)
  const [hasMixAmortization, setHasMixAmortization] = useState(false)

  const [feeTypeExpensesMsg, setFeeTypeExpensesMsg] = useState("")
  const [amortizationExpensesMsg, setAmortizationExpensesMsg] = useState("")

  const { proposalData } = useProposalContext()
  const isMobile = useMediaQuery("mobile")

  const { property_type, financing_term, fee_type } = proposalData
  const { setValue, clearErrors, resetField } = form
  const modalContentComponents = {
    aboutCreditLines: AboutCreditLines,
    aboutAmortization: AboutAmortization
  }
  const setValueOptions = {
    shouldDirty: true,
    shouldTouch: true,
    shouldValidate: true
  }

  const initCreditLine = () => {
    if (!property_type || property_type !== "COMERCIAL") {
      setFeeTypeDisabled(false)
      setFeeTypeExpensesMsg("")
      return
    }
    setFeeTypeExpensesMsg(
      "Para imóveis comerciais, só é permitida a linha de crédito tradicional."
    )
    setValue("fee_type", "Crédito imobiliário", setValueOptions)
    setFeeTypeDisabled(true)
  }

  const getSimulation = async () => {
    const response = await proposalHttpController({
      method: "get",
      flow: "default",
      step: "simulation"
    })

    const { property_value, input_value } = response.step_data

    return { property_value, input_value }
  }

  const initAmortizationFinancingTerm = async () => {
    if (financing_term === undefined || financing_term === null) {
      setAmortizationExpensesMsg("")
      return
    }

    if (financing_term <= 360) {
      const { property_value, input_value } = await getSimulation()

      const inputValuePercentage = (input_value * 100) / property_value
      if (inputValuePercentage >= 20) {
        setHasMixAmortization(true)
      }
    }

    const financigYears = financing_term / 12
    if (financigYears >= 4) {
      setAmortizationExpensesMsg("")
      return
    }
    setAmortizationExpensesMsg(
      "Para prazos menores de 4 anos, só é permitido o Sistema de Amortização Constante (SAC)."
    )
    setValue(
      "amortization_system",
      "SAC (Sistema de Amortização Constante)",
      setValueOptions
    )
    setFinancingTermRuleViolation(true)
    setAmortizationDisabled(true)
  }

  const handleFeeTypeChange = (option) => {
    trackers.feeType(option)
    if (option === "POUPANCA") {
      const value = "SAC (Sistema de Amortização Constante)"

      clearErrors("amortization_system")
      setValue("amortization_system", value, setValueOptions)
      resetField("amortization_system", {
        defaultValue: value
      })
      trackers.amortization("SAC")
      setAmortizationDisabled(true)
      if (!amortizationExpensesMsg) {
        setAmortizationExpensesMsg(
          "Para linha de Crédito Imobiliário com Juros Poupança, só é permitido o Sistema de Amortização Constante (SAC)."
        )
      }
      return
    }
    setAmortizationDisabled(financingTermRuleViolation)
    if (!financingTermRuleViolation) {
      setAmortizationExpensesMsg("")
    }
    return
  }

  const handleAmortizationChange = (option) => {
    trackers.amortization(option)
  }

  const toggleModal = (state, component = null) => {
    if (component) {
      setModalContent(component)
    }
    setModalShow(state)
  }

  const handleModal = (e, state, component = null) => {
    e.preventDefault()
    if (component === "aboutCreditLines") trackers.aboutCreditLines()
    else if (component === "aboutAmortization") trackers.aboutAmortization()
    toggleModal(state, component)
  }

  useEffect(() => {
    initCreditLine()
  }, [])

  useEffect(() => {
    initAmortizationFinancingTerm()
  }, [])

  useEffect(() => {
    handleFeeTypeChange(fee_type)
  }, [])

  return {
    fields,
    handleFeeTypeChange,
    handleAmortizationChange,
    feeTypeDisabled,
    amortizationDisabled,
    modalShow,
    handleModal,
    modalType: isMobile ? "center" : "pop",
    ModalContent: modalContentComponents[modalContent],
    feeTypeExpensesMsg,
    amortizationExpensesMsg,
    hasMixAmortization
  }
}

export default useFormGroupCreditLine
