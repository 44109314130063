import Modal from "@mobi/ds/components/Modal"
import Text from "@mobi/ds/components/Text"

import ResultCard from "./components/ResultCard"
import ResultCardDetails from "./components/ResultCardDetails"

import useResult from "./hooks"

import "./style.scss"

const Result = ({ handleNextStep }) => {
  const {
    isLoading,
    financingOptions,
    financingOptionsText,
    isDetailsVisible,
    setIsDetailsVisible,
    handleDetailsClick,
    financingDetailsSelected
  } = useResult()

  return (
    <div
      className="resultDetails"
      data-aos="fade"
      data-aos-delay="300"
      data-aos-duration="500"
    >
      <Text as="h2" className="resultDetails__title">
        resultado da simulação
      </Text>
      <Text as="p" className="resultDetails__warning">
        Não inclui valores de tarifas
      </Text>
      <Text as="p" className="resultDetails__subtitle">
        {financingOptionsText}
      </Text>
      <ul
        className="resultDetails__list"
        aria-label="Lista com opções de financiamento"
      >
        {financingOptions.map((itemData) => (
          <li
            key={Math.floor(Math.random() * 9999)}
            className="resultDetails__listItem"
          >
            <ResultCard
              data={itemData}
              handleNextStep={handleNextStep}
              handleDetails={handleDetailsClick}
              isLoading={isLoading}
            />
          </li>
        ))}
      </ul>
      <Modal
        type="pop"
        show={isDetailsVisible}
        onCloseClick={() => setIsDetailsVisible(false)}
        className="resultDetails__modal"
      >
        <ResultCardDetails
          data={financingDetailsSelected}
          isLoading={isLoading}
          handleNextStep={(data) => {
            setIsDetailsVisible(false)
            handleNextStep(data)
          }}
        />
      </Modal>
    </div>
  )
}

export default Result
