export const fields = {
  isAccountHolder: {
    type: "radio",
    name: "is_account_holder",
    options: [
      {
        id: "is_account_holder_false",
        value: "nao",
        label: "não",
        checked: true
      },
      {
        id: "is_account_holder_true",
        value: "sim",
        label: "sim"
      }
    ],
    sendFormat: "bool",
    fetchFormat: "bool"
  }
}
