const linksEnum = {
  AUTHORIZATION_TERM: "https://www.itau.com.br/seguranca/termo-autorizacao/",
  POSITIVE_RECORD: "https://www.itau.com.br/cadastro-positivo/",
  CREDIT_INFORMATION_SYSTEM:
    "https://www.itau.com.br/seguranca/sistema-informacao-credito/",
  TERMS_OF_USE: "https://www.itau.com.br/seguranca/termos-de-uso/",
  BUSCA_CEP: "https://buscacepinter.correios.com.br/app/endereco/index.php",
  ACCOMPANY_PROPOSAL:
    "https://www.itau.com.br/emprestimos-financiamentos/credito-imobiliario/acompanhar-proposta",
  OPEN_ACCOUNT:
    "https://www.itau.com.br/abra-sua-conta/tp/home/?utm_source=st&utm_medium=nd&utm_campaign=st-conv-nd-st-nd-all&utm_content=nd-nd-nd-iacnta-nd-pfaqu-MT00000831-imobproposta&utm_term="
}

export default linksEnum
