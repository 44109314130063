const useProposal = ({ pageContext }) => {
  const { currentFlow } = pageContext

  const initialStepData = {
    currentFlow
  }

  return { initialStepData }
}

export default useProposal
