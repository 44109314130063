import { Icon } from "@garantidos/components"

import Text from "@mobi/ds/components/Text"

import "./style.scss"

const AttentionBox = ({ icon, title, text }) => (
  <div className="attentionBox">
    <Icon icon={icon} type="outlined" className="attentionBox__icon" />
    <div className="attentionBox__content">
      <Text as="p" weight="400" className="attentionBox__title">
        {text}
      </Text>
    </div>
  </div>
)

export default AttentionBox
