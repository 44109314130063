import { formatters } from "@garantidos/utils"

const insurerName = {
  ITAU: "Itaú seguradora",
  TOKIO: "Tokio Marine Seguradora"
}

export const detailListData = (fieldsToShow) => [
  {
    label: "valor do imóvel",
    value: formatters.toCurrency(fieldsToShow?.property_value)
  },
  {
    label: "valor da entrada",
    value: formatters.toCurrency(fieldsToShow?.input_value)
  },
  {
    label: "prazo",
    value: formatters.toTextYears(fieldsToShow?.financing_term)
  },
  {
    label: "data de nasc.",
    value: formatters.toDate(fieldsToShow?.birthdate)
  },
  {
    label: "seguradora",
    value: insurerName[fieldsToShow?.insurer]
  }
]
