import errorsEnum from "pages/Proposal/constants/errorsEnum"

import ImageCardsdebtRenegotiations from "./images/debt-renegotiations.png"
import ImageCardsFederalRevenue from "./images/federal-revenue.png"
import ImageCardsPositiveRegister from "./images/positive-register.png"

const {
  GENERIC,
  SAME_DOCUMENT_PROPOSAL,
  ERROR_INSTABILITY,
  CPF_NO_CREDIT_NEW_QUERY,
  CPF_MANY_SIMULATIONS
} = errorsEnum

const pageEvents = {
  [SAME_DOCUMENT_PROPOSAL]: {
    gaText: "ErroCPF",
    segmentInfo: "error same document"
  },
  [GENERIC]: {
    gaText: "ErroInstabilidade",
    segmentInfo: "error instability"
  },
  [ERROR_INSTABILITY]: {
    gaText: "ErroInstabilidade",
    segmentInfo: "error instability"
  },
  [CPF_MANY_SIMULATIONS]: {
    gaText: "ErroExcessoPropostasPorMinuto",
    segmentInfo: "error excess proposals per minute"
  },
  [CPF_NO_CREDIT_NEW_QUERY]: {
    gaText: "ErroSemCreditoEnvioProposta",
    segmentInfo: "error no credit send proposal"
  }
}

const titles = {
  [SAME_DOCUMENT_PROPOSAL]:
    "opa! já existe uma solicitação de contato para o CPF informado ;)",
  [GENERIC]: "desculpe, estamos passando por instabilidade",
  [ERROR_INSTABILITY]: "desculpe, estamos passando por instabilidade",
  [CPF_NO_CREDIT_NEW_QUERY]:
    "infelizmente, não pudemos continuar sua simulação",
  [CPF_MANY_SIMULATIONS]: "você excedeu o número de propostas por minuto"
}

const infos = {
  [SAME_DOCUMENT_PROPOSAL]: "Aguarde o contato de um de nossos especialistas",
  [GENERIC]: "",
  [ERROR_INSTABILITY]: "Por favor, tente novamente em alguns instantes",
  [CPF_NO_CREDIT_NEW_QUERY]:
    "Você excedeu o limite de cotas reservadas para o seu CPF, confira algumas dicas abaixo para aumentar as suas chances de aprovação",
  [CPF_MANY_SIMULATIONS]: "Por favor, tente novamente em alguns instantes"
}

const stepContexts = {
  [SAME_DOCUMENT_PROPOSAL]: {
    stepId: "consorcio-e2e-error-cpf-14",
    stepName: "error-cpf",
    stepNumber: 14
  },
  [GENERIC]: {
    stepId: "consorcio-e2e-error-instability-14",
    stepName: "error-instability",
    stepNumber: 14
  },
  [ERROR_INSTABILITY]: {
    stepId: "consorcio-e2e-error-instability-14",
    stepName: "error-instability",
    stepNumber: 14
  },
  [CPF_NO_CREDIT_NEW_QUERY]: {
    stepId: "consorcio-e2e-error-no-credit-send-proposal-14",
    stepName: "error-no-credit-send-proposal",
    stepNumber: 14
  },
  [CPF_MANY_SIMULATIONS]: {
    stepId: "consorcio-e2e-error-excess-proposals-per-minute-14",
    stepName: "error-excess-proposals-per-minute",
    stepNumber: 14
  }
}

const buttonInfos = {
  [SAME_DOCUMENT_PROPOSAL]: [
    {
      label: "voltar para o início",
      Icon: null,
      variant: "secondary",
      action: "restart",
      trackEventObject: {
        gaText: "VoltarParaInicio",
        segmentInfo: {
          event: "return step",
          data: {
            step: "error same document"
          }
        }
      }
    }
  ],
  [GENERIC]: [
    {
      label: "voltar",
      Icon: null,
      variant: "secondary",
      action: "prevStep",
      trackEventObject: {
        gaText: "Voltar",
        segmentInfo: {
          event: "return step",
          data: {
            step: "error instability"
          }
        }
      }
    }
  ],
  [ERROR_INSTABILITY]: [
    {
      label: "tentar novamente",
      Icon: null,
      variant: "primary",
      action: "nextStep",
      trackEventObject: {
        gaText: "TentarNovamente",
        segmentInfo: {
          event: "return step",
          data: {
            step: "error instability"
          }
        }
      }
    }
  ],
  [CPF_NO_CREDIT_NEW_QUERY]: [
    {
      label: "voltar para o início",
      Icon: null,
      variant: "primary",
      action: "restart",
      trackEventObject: {
        gaText: "voltarParaInicio",
        segmentInfo: {
          event: "return step",
          data: {
            step: "error no credit send proposal"
          }
        }
      }
    }
  ],
  [CPF_MANY_SIMULATIONS]: [
    {
      label: "voltar para o início",
      variant: "secondary",
      action: "restart",
      value: "",
      trackEventObject: {
        gaText: "VoltarParaInicio",
        segmentInfo: {
          event: "return step inicio",
          data: {
            step: "error excess proposals per minute"
          }
        }
      }
    },
    {
      label: "simular novamente",
      action: "prevStep",
      value: "",
      trackEventObject: {
        gaText: "SimularNovamente",
        segmentInfo: {
          event: "return step",
          data: {
            step: "error excess proposals per minute"
          }
        }
      }
    }
  ]
}

const contactCardTexts = {
  [SAME_DOCUMENT_PROPOSAL]: "",
  [GENERIC]:
    "Você também pode pedir que um dos nossos especialistas te ajudem a escolher o melhor consórcio para o seu momento.",
  [ERROR_INSTABILITY]:
    "Você também pode pedir que um dos nossos especialistas te ajudem a escolher o melhor consórcio para o seu momento."
}

const cards = [
  {
    image: ImageCardsPositiveRegister,
    imageAlt: "cadastro positivo",
    title: "cadastro positivo",
    text: "Ative seu Cadastro Positivo para nos fornecer mais informações sobre você;"
  },
  {
    image: ImageCardsFederalRevenue,
    imageAlt: "receita federal",
    title: "receita federal",
    text: "Consulte a Receita Federal para ver se existe alguma pendência no seu CPF;"
  },
  {
    image: ImageCardsdebtRenegotiations,
    imageAlt: "renegociações de dívidas",
    title: "renegociações de dívidas",
    text: "Verfique se não existem renegociações andamento, contas atrasadas, etc."
  }
]

export {
  pageEvents,
  titles,
  infos,
  stepContexts,
  buttonInfos,
  contactCardTexts,
  cards
}
