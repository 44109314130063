import { Form, HousingAlert, Sender } from "@garantidos/components"

import Text from "@mobi/ds/components/Text"

import FormGroupAccountHolder from "./components/FormGroupAccountHolder"
import FormGroupCreditLine from "./components/FormGroupCreditLine/index"
import FormGroupEasiness from "./components/FormGroupEasiness/index"
import FormGroupReferral from "./components/FormGroupReferral"

import useFinancingConditions from "./hooks"

import "./style.scss"

const FinancingConditions = () => {
  const {
    handlePrevStep,
    onSubmitCallback,
    form,
    errorMessage,
    setErrorMessage,
    isLoading
  } = useFinancingConditions()

  const { isValid } = form

  return (
    <div className="financingConditions">
      <div className="financingConditions__formWrapper">
        <HousingAlert
          type="error"
          message={errorMessage}
          setMessage={setErrorMessage}
        />

        <Text
          as="h1"
          size="lg"
          className="financingConditions__title"
          data-aos="fade-left"
          data-aos-delay="200"
          data-aos-duration="800"
        >
          sobre o financiamento
        </Text>
        <Text
          as="p"
          size="md"
          className="financingConditions__subtitle"
          data-aos="fade-left"
          data-aos-delay="300"
          data-aos-duration="800"
        >
          Conte-nos um pouco sobre como você pretende financiar o imóvel
          desejado
        </Text>

        <Form
          onSubmit={onSubmitCallback}
          form={form}
          data-aos="fade-left"
          data-aos-delay="400"
          data-aos-duration="800"
        >
          <FormGroupCreditLine form={form} isLoading={isLoading} />
          <FormGroupEasiness form={form} isLoading={isLoading} />
          <FormGroupReferral form={form} isLoading={isLoading} />
          <FormGroupAccountHolder form={form} isLoading={isLoading} />

          <Sender
            data_testid="submit"
            onPrev={handlePrevStep}
            isNextDisabled={!isValid}
            isLoading={isLoading}
          />
        </Form>
      </div>
    </div>
  )
}

export default FinancingConditions
