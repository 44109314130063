import { Helmet } from "react-helmet"

import { HousingAlert } from "@garantidos/components"

import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"

import classNames from "classnames"
import IconDesktop from "icons/rejection-desktop.svg"
import IconMobile from "icons/rejection-mobile.svg"

import errorsEnum from "../../constants/errorsEnum"

import useRejection from "./hooks"

import "./style.scss"

const Rejection = () => {
  const {
    stepTitle,
    title,
    info,
    cards,
    isMobile,
    errorMessage,
    setErrorMessage,
    errorCode
  } = useRejection()

  const rejectionClassNames = classNames({
    rejection__contentContainer:
      errorCode !== errorsEnum.CPF_NO_CREDIT_NEW_QUERY,
    newRejection__contentContainer:
      errorCode === errorsEnum.CPF_NO_CREDIT_NEW_QUERY
  })

  return (
    <div className="rejection">
      <div className="rejection__mainContainer">
        <Helmet>
          <title>{stepTitle}</title>
        </Helmet>
        <div className="rejection__container">
          <div
            className={rejectionClassNames}
            data-aos="fade"
            data-aos-duration="150"
            data-aos-offset="-1000"
          >
            <HousingAlert
              message={errorMessage}
              setMessage={setErrorMessage}
              focusOnText
              type={
                errorMessage === "Proposta já enviada" ? "success" : "error"
              }
            />
            <div className="rejection__iconContainer">
              <If
                condition={isMobile}
                renderIf={
                  <IconMobile className="rejection__icon" aria-hidden />
                }
                renderElse={
                  <IconDesktop className="rejection__icon" aria-hidden />
                }
              />
            </div>
            <Text
              as="h1"
              className={classNames({
                rejection__title:
                  errorCode !== errorsEnum.CPF_NO_CREDIT_NEW_QUERY,
                newRejection__title:
                  errorCode === errorsEnum.CPF_NO_CREDIT_NEW_QUERY
              })}
            >
              {title}
            </Text>
            <If
              condition={!!info}
              renderIf={
                <Text
                  as="p"
                  className={classNames({
                    rejection__info:
                      errorCode !== errorsEnum.CPF_NO_CREDIT_NEW_QUERY,
                    newRejection__info:
                      errorCode === errorsEnum.CPF_NO_CREDIT_NEW_QUERY
                  })}
                >
                  {info}
                </Text>
              }
            />
          </div>
        </div>
        <If
          condition={errorCode === errorsEnum.CPF_NO_CREDIT_NEW_QUERY}
          renderIf={
            <div className="newRejection__container">
              <div className="newRejection__contentContainer">
                <Text as="h2" className="newRejection__contentTitle">
                  o que fazer para aumentar minha chance de aprovação?
                </Text>
                <div className="newRejection__cardsWrapper">
                  {cards?.map((card) => (
                    <div className="newRejection__cards">
                      <img
                        src={card.image}
                        alt={card.imageAlt}
                        className="newRejection__cardsImage"
                        aria-hidden
                      />
                      <div className="newRejection__cardsContent">
                        <Text as="h3" className="newRejection__cardsTitle">
                          {card.title}
                        </Text>
                        <Text as="p" className="newRejection__cardsText">
                          {card.text}
                        </Text>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="newRejection__contentInfo">
                  <Text as="h2" className="newRejection__contentTitle">
                    entenda como funciona a análise para conquistar a cota de
                    consórcio
                  </Text>
                  <Text as="p" className="newRejection__contentText">
                    Avaliamos seus dados com informações disponíveis em
                    cadastros públicos, orgãos de proteção ao crédito, Cadastro
                    Positivo, e também seus pagamentos em dia e renda.
                  </Text>
                </div>

                <div className="newRejection__contentInfo newRejection__contentInfo--margin">
                  <Text as="h2" className="newRejection__contentTitle">
                    porque pedimos o seu número de celular?
                  </Text>
                  <Text as="p" className="newRejection__contentText">
                    Verificaremos no futuro a disponibilidade de adquirir uma
                    cota de consórcio e podemos entrar em contato para acelerar
                    a realização do seu sonho.
                  </Text>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  )
}

export default Rejection
