import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf, proposalId) => {
  const ga = {
    label: "IT:NL:NCC:CredImob:Simulador:SolicitacaoEnviadaSucesso",
    hashedCpf
  }
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/envio-proposta-sucesso",
    implementationTeam: "imob:v01",
    proposalId: proposalId.toString()
  }
  const segment = "Complete Registration"

  trackPageview({ ga, ga4, segment })
}

const proposal = (hashedCpf, proposalId) => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/envio-proposta-sucesso",
    hashedCpf,
    implementationTeam: "imob:v01",
    detail: "inicio-simulacao-voltar-inicio",
    eventName: "proposal",
    proposalId: proposalId.toString()
  }
  trackEvent({ ga4 })
}

const backHome = (hashedCpf) => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/envio-proposta-sucesso",
    hashedCpf,
    implementationTeam: "imob:v01",
    detail: "btn:voltar-inicio",
    eventName: "click"
  }
  trackEvent({ ga4 })
}

const accountNotHolder = (hashedCpf, proposalId) => {
  const ga = {
    label: "BTN:Simulador:AcompanheSuaProposta",
    action: "Simulador",
    hashedCpf
  }
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/envio-proposta-sucesso",
    implementationTeam: "imob:v01",
    detail: "btn:acompanhar-proposta",
    hashedCpf,
    proposalId: proposalId.toString(),
    eventName: "click"
  }

  trackEvent({ ga, ga4 })
}

const openAccount = (hashedCpf, proposalId) => {
  const ga = {
    label: "BTN:Simulador:AbraSuaConta",
    action: "Simulador",
    hashedCpf
  }
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/envio-proposta-sucesso",
    implementationTeam: "imob:v01",
    detail: "btn:abra-sua-conta",
    proposalId: proposalId?.toString(),
    eventName: "click"
  }

  const segment = "crossell-account-proposal-imob"

  trackEvent({ ga, ga4, segment })
}

const nextSteps = (hashedCpf, proposalId) => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/envio-proposta-sucesso",
    implementationTeam: "imob:v01",
    detail: "btn:acompanhar-proposta",
    hashedCpf,
    proposalId: proposalId.toString(),
    eventName: "click"
  }

  trackEvent({ ga, ga4 })
}

const likert = (value, prefix = "") => {
  const ga = {
    label: `BTN:Simulacao:${prefix}${value}`
  }

  trackEvent({ ga })
}

const likertGa4 = (value) => {
  let ga4ExperienceTrack = ""

  switch (value) {
    case "BAD":
      ga4ExperienceTrack = "ruim"
      break
    case "NOT_SATISFIED":
      ga4ExperienceTrack = "nao-gostei"
      break
    case "NOT_SURE":
      ga4ExperienceTrack = "neutro"
      break
    case "LIKED":
      ga4ExperienceTrack = "gostei"
      break
    case "LOVED":
      ga4ExperienceTrack = "adorei"
      break
  }

  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/envio-proposta-sucesso",
    implementationTeam: "imob:v01",
    detail: `envio-proposta-sucesso-${ga4ExperienceTrack}`,
    eventName: "score"
  }

  trackEvent({ ga4 })
}

const likertSubmit = (experience, nonTagsDescription) => {
  const segment = `${experience} - ${nonTagsDescription}`
  trackEvent({ segment })
}

const marketplace = (name) => {
  const cards = {
    CartaoCreditoClick: {
      ga: "BTN:Simulador:CartaoCreditoClick",
      ga4: "btn:crossell-peca-ja-o-seu",
      segment: "crossell-click-proposal-imob"
    },
    Emprestimo: {
      ga: "BTN:Simulador:Emprestimo",
      ga4: "btn:confira-agora",
      segment: "crossell-shopping-proposal-imob"
    },
    FinanciamentoVeiculo: {
      ga: "BTN:Simulador:Financiamento",
      ga4: "btn:quero-um-carro-novo",
      segment: "crossell-vehicles-proposal-imob"
    }
  }

  const ga = {
    label: cards[name].ga
  }

  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/envio-proposta-sucesso",
    implementationTeam: "imob:v01",
    detail: cards[name].ga4,
    eventName: "click"
  }

  const segment = cards[name].segment
  trackEvent({ ga })
  trackEvent({ ga4 })
  trackEvent({ segment })
}

export {
  pageview,
  proposal,
  backHome,
  accountNotHolder,
  openAccount,
  nextSteps,
  likert,
  likertGa4,
  likertSubmit,
  marketplace
}
