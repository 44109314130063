const aboutCreditLinesData = [
  {
    title: "Crédito Imobiliário",
    content: [
      "Taxa pré-fixada que não irá variar ao longo do tempo, ela será a mesma desde a sua contratação. Nesta linha de crédito, seu saldo devedor será atualizado mensalmente pela Taxa Referencial (TR)."
    ]
  },
  {
    title: "Crédito Imobiliário com juros da poupança",
    content: [
      "Nesta modalidade a sua taxa acompanha o rendimento da poupança, assim você economiza quando ela está baixa e conta com um teto caso ela suba.",
      "A taxa é composta pela soma de uma parte fixa e outra variável. A parte fixa depende do seu tipo de conta e do seu relacionamento com o Itaú.",
      "Já a parte variável corresponde ao rendimento da poupança, ou seja, depende da variação da Selic. A regra é que quando a Selic está menor que 8,50% ao ano a poupança equivale a 70% do valor da Selic, agora quando ela passa desse valor, o rendimento da poupança é travado em 6,17% ao ano, que quando somado com a sua parte fixa resulta no teto da taxa.",
      "O valor da taxa Selic é decidido a cada 45 dias pelo Comitê de Política Monetária do Banco Central (COPOM).",
      "Ou seja, a taxa final é a soma da parte fixa definida na sua contratação com o rendimento da poupança. Nesta linha o seu saldo devedor também será atualizado pela Taxa Referencial (TR)."
    ]
  }
]

export default aboutCreditLinesData
