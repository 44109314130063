const cardData = {
  options: [
    {
      feeType: "PADRAO",
      title: "crédito imobiliário",
      subText: "a linha de crédito que você já conhece",
      diferential:
        "Nessa opção a taxa é fixa, ou seja você sabe quanto vai pagar do começo ao fim do contrato.",
      dataTestId: "submit_padrao"
    },
    {
      feeType: "POUPANCA",
      title: "crédito imobiliário juros poupança",
      subText: "variação do índice da poupança + taxa fixa",
      diferential:
        "Nessa modalidade a taxa varia de acordo com o índice da poupança, ou seja, você economiza quando a poupança está baixa e tem um teto caso ela suba.",
      dataTestId: "submit_poupanca"
    }
  ]
}

export default cardData
