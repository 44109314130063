import { Field } from "@garantidos/components"

import AriaLabel from "@mobi/components/AriaLabel"
import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"

import useFormGroupEasines from "./hooks"

import "./style.scss"

import itbiSubText from "./data"

const FormGroupEasiness = ({ form, isLoading }) => {
  const {
    fields,
    handleFinanceExpensesInput,
    financeExpensesMsg,
    showHomeInsuranceField,
    showHomeInsuranceLegal,
    handleHomeInsurance,
    showMore,
    handleShowMore,
    trackHomeInsuranceLink,
    trackHomeInsuranceLegal
  } = useFormGroupEasines()

  const { financeExpenses, homeInsurance } = fields

  return (
    <div className="formGroupEasiness">
      <Text as="h2" className="formGroupEasiness__subtitle">
        facilidades
      </Text>
      <div className="formGroupEasiness__card">
        <div className="formGroupEasiness__cardCheckboxGroup">
          <Field
            data-testid={financeExpenses.name}
            field={financeExpenses}
            form={form}
            onInput={(event) =>
              handleFinanceExpensesInput(event.target.checked)
            }
            isDisabled={isLoading}
          />
        </div>
        <div className="formGroupEasiness__divider" />
        <Text as="p" className="formGroupEasiness__cardInfo">
          <If
            condition={showMore}
            renderIf={itbiSubText}
            renderElse={`${itbiSubText.substring(0, 59)}...`}
          />
          <span
            role="button"
            className="formGroupEasiness__readMoreButton"
            onClick={handleShowMore}
          >
            <If
              condition={showMore}
              renderIf={"mostrar menos"}
              renderElse={"mostrar mais"}
            />
          </span>
        </Text>
        <If
          condition={!!financeExpensesMsg}
          renderIf={
            <>
              <div className="formGroupEasiness__divider" />
              <Text as="p" className="formGroupEasiness__cardInfo--warning">
                {financeExpensesMsg}
              </Text>
            </>
          }
        />
      </div>
      <If
        condition={!!showHomeInsuranceField}
        renderIf={
          <div className="formGroupEasiness__card">
            <div className="formGroupEasiness__cardCheckboxGroup">
              <Field
                field={homeInsurance}
                form={form}
                onInput={(event) => handleHomeInsurance(event.target.checked)}
                isDisabled={isLoading}
              />
            </div>
            <div className="formGroupEasiness__divider" />
            <Text as="p" className="formGroupEasiness__cardInfo">
              Esse é um seguro opcional e exclusivo para quem contrata crédito
              imobiliário no Itaú, que ajuda a proteger os bens de sua
              residência, como móveis e eletrônicos. Além disso, oferece
              assistências 24 horas para cuidar do seu lar por R$39,90 ao mês,
              durante 5 anos, renováveis por mais 5.
            </Text>
            <Text as="p" className="formGroupEasiness__cardInfo">
              <AriaLabel ariaText="informações importantes sobre o seguro">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="formGroupEasiness__link"
                  onClick={trackHomeInsuranceLink}
                  href="https://www.itau.com.br/content/dam/itau/varejo/seguro-residencial-da-porto.pdf"
                  aria-label="Saiba mais sobre o sistema de informações de crédito"
                >
                  informações importantes sobre o seguro
                </a>
              </AriaLabel>
            </Text>
            <If
              condition={!!showHomeInsuranceLegal}
              renderIf={
                <AriaLabel ariaText="ao continuar, declaro que li e estou de acordo com as condições gerais do seguro residencial">
                  <div className="formGroupEasiness__divider" />
                  <Text as="p" className="formGroupEasiness__cardInfo--legal">
                    <span aria-hidden>
                      ao continuar, declaro que li e estou de acordo com
                      as&nbsp;
                    </span>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      onClick={trackHomeInsuranceLegal}
                      href="https://www.portoseguro.com.br/NovoInstitucional/static_files/CGs/3449-CG-residencial-parcerias.pdf"
                      className="formGroupEasiness__link"
                    >
                      condições gerais
                    </a>
                    <span aria-hidden>&nbsp;do seguro residencial.</span>
                  </Text>
                </AriaLabel>
              }
            />
          </div>
        }
      />
    </div>
  )
}

export default FormGroupEasiness
