const errorsEnum = {
  GENERIC: "genericError",
  SAME_DOCUMENT_PROPOSAL: "sameDocumentProposal",
  POTENTIAL_CPF: "potentialCpf",
  CPF_NO_CREDIT: "cpfNoCredit",
  ERROR_INSTABILITY: "errorInstability",
  CPF_NO_CREDIT_NEW_QUERY: "cpfNoCreditNewQuery",
  CPF_NO_GROUP: "cpfNoGroup",
  CPF_MANY_SIMULATIONS: "cpfManySimulations"
}

export default errorsEnum
