import { fieldsCommonOptions } from "@garantidos/data"

const {
  NATIONALITY_OPTIONS,
  UF_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  OCCUPATION_CATEGORY_OPTIONS,
  OCCUPATION_OPTIONS
} = fieldsCommonOptions

const PROPERTY_TYPE_OPTIONS = [
  { label: "Residencial", value: "RESIDENCIAL" },
  { label: "Comercial", value: "COMERCIAL" }
]

const FEE_TYPE_OPTIONS = [
  {
    label: "Crédito imobiliário",
    value: "PADRAO"
  },
  {
    label: "Crédito imobiliário com juros da poupança",
    value: "POUPANCA"
  }
]

const AMORTIZATION_SYSTEM_OPTIONS = [
  {
    label: "SAC (Sistema de Amortização Constante)",
    value: "SAC"
  },
  {
    label: "MIX (Sistema Misto de Amortização)",
    value: "MIX"
  }
]

const PROPERTY_TYPE_NAME = {
  RESIDENCIAL: "Residencial",
  COMERCIAL: "Comercial"
}

const INSURER_FULLNAME = {
  ITAU: "Itaú Seguradora",
  TOKIO: "Tokio Marine Seguradora"
}

const HAS_PROPERTY_OPTIONS = [
  { label: "Sim", value: true },
  { label: "Não, estou só simulando", value: false }
]

const TIME_ACQUIRE_PROPERTY_OPTIONS = [
  { label: "Em até 1 mês", value: "1 MES" },
  { label: "Em até 3 meses", value: "3 MESES" },
  { label: "Acima de 3 meses", value: "MAIS 3 MESES" }
]

export {
  NATIONALITY_OPTIONS,
  UF_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  OCCUPATION_CATEGORY_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  FEE_TYPE_OPTIONS,
  AMORTIZATION_SYSTEM_OPTIONS,
  PROPERTY_TYPE_NAME,
  INSURER_FULLNAME,
  OCCUPATION_OPTIONS,
  HAS_PROPERTY_OPTIONS,
  TIME_ACQUIRE_PROPERTY_OPTIONS
}
