import { useEffect } from "react"

import { focusOnLogo } from "@garantidos/utils"

import Button from "@mobi/ds/components/Button"
import useMediaQuery from "@mobi/hooks/useMediaQuery"
import { useStep } from "@mobi/libraries/step"

import useRejectionRedirect from "pages/Proposal/hooks/useRejectionRedirect"
import { steps } from "pages/Proposal/steps"

import errorsEnum from "pages/Proposal/constants/errorsEnum"

import { useProposalContext } from "contexts/proposal"

import {
  buttonInfos,
  cards,
  contactCardTexts,
  infos,
  pageEvents,
  stepContexts,
  titles
} from "./data"
import * as trackers from "./trackings"

const DEFAULT_TITLE = "desculpe, estamos passando por instabilidade"
const DEFAULT_BUTTON_LABEL = "voltar para o início"

const useRejection = () => {
  const {
    stepInfo,
    proposalData,
    fetchProposalData,
    sendProposalData,
    errorMessage,
    setErrorMessage,
    errorHandler,
    isLoading
  } = useProposalContext()
  const { redirectToRejectionPage } = useRejectionRedirect()
  const { next, prev } = useStep()
  const isMobile = useMediaQuery("mobile")

  const { currentStep, nextStep } = stepInfo
  const {
    rejection: { title: stepTitle }
  } = steps

  const {
    SAME_DOCUMENT_PROPOSAL,
    GENERIC,
    ERROR_INSTABILITY,
    CPF_NO_CREDIT_NEW_QUERY
  } = errorsEnum

  function getErrorCode() {
    if (currentStep === "offerUnavailable") {
      return CPF_NO_CREDIT_NEW_QUERY
    } else {
      const { errorCode } = proposalData
      return errorCode
    }
  }

  const errorCode = getErrorCode()

  const restartFlow = async () => {
    try {
      await fetchProposalData({ step: "softlead" })
      prev("softlead")
    } catch (error) {
      errorHandler(error)
    }
  }

  const handlePrevStep = async () => {
    try {
      await fetchProposalData({ step: currentStep })

      prev(currentStep)
    } catch (error) {
      errorHandler(error)
    }
  }

  const handleNextStep = async () => {
    try {
      const payload = {}
      await sendProposalData({ payload })
      next(nextStep)
    } catch (error) {
      errorHandler(error)
    }
  }

  const handleDropFlow = async (contactType = "PHONE") => {
    if (isLoading) return

    try {
      const fromStep = steps[currentStep]
      trackers.dropFlow({
        pageEvents,
        errorCode,
        contactType,
        stepContexts,
        fromStep
      })
      trackers.formSubmit(errorCode, stepContexts)
      trackers.elementClicked(errorCode, stepContexts, contactType)

      const payload = { contact_whatsapp: contactType === "WHATSAPP" }

      await sendProposalData({ payload, flow: "bypass", step: "send-lead" })

      next("finished")
    } catch (error) {
      if (error?.statusCode === 409) {
        redirectToRejectionPage(errorsEnum.SAME_DOCUMENT_PROPOSAL)
        return
      }
      errorHandler(error)
    }
  }

  const buttonActions = {
    prevStep: handlePrevStep,
    restart: restartFlow,
    nextStep: handleNextStep
  }

  const contactCardsCallback = {
    [SAME_DOCUMENT_PROPOSAL]: {
      onClickPhone: () => handleDropFlow("PHONE")
    },
    [GENERIC]: {
      onClickPhone: () => handleDropFlow("PHONE")
    },
    [ERROR_INSTABILITY]: {
      onClickPhone: () => handleDropFlow("PHONE")
    }
  }

  const handleClick = async (action, trackEventObject, payload = {}) => {
    if (isLoading) return

    const fromStep = steps[currentStep]
    trackers.buttonCallback(pageEvents, errorCode, trackEventObject, fromStep)

    const callback = buttonActions[action]

    callback && (await callback(payload))
  }

  const mountButtons = (errorCode) => {
    const buttonInfo = buttonInfos[errorCode]
    if (!buttonInfo) {
      const button = (
        <Button
          variant="secondary"
          onClick={restartFlow}
          className="rejection__button"
          fluid
        >
          {DEFAULT_BUTTON_LABEL}
        </Button>
      )
      return button
    }
    const buttons = buttonInfo.map(
      ({ label, Icon, variant, action, trackEventObject, payload }) => (
        <Button
          key={`${label}-${errorCode}`}
          {...(Icon
            ? {
                leftIcon: (
                  <Icon aria-hidden="true" className="rejection__buttonIcon" />
                )
              }
            : {})}
          variant={variant}
          className="rejection__button"
          onClick={() => handleClick(action, trackEventObject, payload)}
          fluid
        >
          {label}
        </Button>
      )
    )
    return buttons
  }

  const trackPage = () => {
    const fromStep = steps[currentStep]
    trackers.pageview(pageEvents, errorCode, fromStep)
    trackers.formErrored(fromStep)
  }

  useEffect(() => {
    setTimeout(focusOnLogo, 200)
    trackPage()
  }, [])

  return {
    stepTitle,
    title: titles[errorCode] ?? DEFAULT_TITLE,
    info: infos[errorCode] ?? "",
    contactCardText: contactCardTexts[errorCode] ?? "",
    contactCardCallback: contactCardsCallback[errorCode] ?? {
      onClickPhone: () => handleDropFlow("PHONE")
    },
    cards,
    Buttons: mountButtons(errorCode),
    isMobile,
    errorMessage,
    setErrorMessage,
    errorCode
  }
}

export default useRejection
