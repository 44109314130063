import { useEffect } from "react"

import {
  useForm,
  usePayloadFormatters,
  useStepTransition
} from "@garantidos/hooks"
import { getItem, setItem } from "@garantidos/utils"
import { resetFocus } from "@garantidos/utils/js/accessibility"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

import validatorsDictionary from "pages/Proposal/hooks/validators"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { fields } from "./fields"
import * as trackers from "./trackings"

const useSoftlead = () => {
  const { form } = useForm({
    fields,
    context: ProposalContext,
    validatorsDictionary
  })
  const {
    cpfData,
    sendProposalData,
    errorMessage,
    setErrorMessage,
    errorHandler,
    isLoading,
    setIsStepVisible
  } = useProposalContext()
  const { stepNavigator } = useStepTransition(setIsStepVisible)
  const { formatSendPayload } = usePayloadFormatters(fields)

  const { hashedCpf } = cpfData
  const isMobile = useMediaQuery("mobile")

  useEffect(() => {
    trackers.pageview(hashedCpf)

    const backButton = document.querySelector(".ds-header__button-back")
    backButton.onclick = () => trackers.backHome(hashedCpf)

    resetFocus()
  }, [])

  useEffect(() => {
    if (hashedCpf) {
      trackers.cpfCollect(hashedCpf)
    }
  }, [hashedCpf])

  const onSubmitCallback = async (formData) => {
    if (isLoading) return
    try {
      trackers.advanceGA4()
      const payload = formatSendPayload(formData)
      payload.mobile = isMobile
      payload.a3sLeadUUID = getItem("a3sLead")
      const response = await sendProposalData({
        payload,
        clearStepNaviagation: false
      })
      const nextStep = response.stepNavigation.currentStep
      setItem("eq3_uuid", response.stepData.eq3_uuid)
      setTimeout(() => {
        stepNavigator("next", nextStep)
      }, 50)
    } catch (error) {
      errorHandler(error)
      trackers.alert(hashedCpf, error)
    }
  }

  return {
    onSubmitCallback,
    fields,
    form,
    errorMessage,
    setErrorMessage,
    isLoading,
    trackLinkClick: trackers.trackLinkClick
  }
}

export default useSoftlead
