export const fields = {
  financeExpenses: {
    type: "checkbox",
    name: "finance_expenses",
    label: "deseja financiar as despesas de ITBI e custos com cartório?"
  },
  financePropertyValuation: {
    type: "checkbox",
    name: "finance_property_valuation",
    label: "deseja financiar a tarifa de avaliação do imóvel?"
  },
  homeInsurance: {
    type: "checkbox",
    name: "home_insurance",
    label: "deseja contratar o seguro residencial Porto Seguro?"
  }
}
