import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = {
    label: "IT:NL:NCC:CredImob:Simulador:DadosComplementares",
    hashedCpf
  }
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/dados-complementares",
    implementationTeam: "imob:v01"
  }
  const segment = "Lead Page Viewed"

  trackPageview({ ga, ga4, segment })
}

const advance = () => {
  const segment = "Personal Information Sent"
  trackEvent({ segment })
}

const backHome = (hashedCpf) => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/dados-complementares",
    hashedCpf,
    implementationTeam: "imob:v01",
    detail: "btn:voltar-inicio",
    eventName: "click"
  }
  trackEvent({ ga4 })
}

const previousGA4 = () => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/dados-complementares",
    implementationTeam: "imob:v01",
    detail: "btn:voltar",
    eventName: "click"
  }

  trackEvent({ ga4 })
}

const alert = (hashedCpf, error) => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/dados-complementares",
    hashedCpf,
    implementationTeam: "imob:v01",
    eventName: "alert",
    description: "erro",
    detail: error.message
      ?.toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replaceAll(/ /g, "-")
      .replaceAll(/!/g, "")
  }

  trackEvent({ ga4 })
}

export { pageview, backHome, advance, previousGA4, alert }
