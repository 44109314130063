import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = { label: "IT:NL:NCC:CredImob:Simulador:Simulador", hashedCpf }
  const ga4 = {
    customPath: "/credito/imob/isolado/contratacao/simulacao/simular-credito",
    implementationTeam: "imob:v01"
  }
  const segment = "Simulation Page Viewed"

  trackPageview({ ga, ga4, segment })
}

const advance = () => {
  const segment = "Simulation Sent"

  trackEvent({
    segment
  })
}

const qualified = (payload) => {
  trackEvent({
    ga: {
      label: `BTN:Simulador:Simulador:PossuiImovel:${payload.has_property}`
    }
  })

  if (payload.time_acquire_property) {
    trackEvent({
      ga: {
        label: `BTN:Simulador:Simulador:PensaAdquirirImovel:${payload.time_acquire_property.replaceAll(
          " ",
          ""
        )}`
      }
    })
  }
}

const backHome = (hashedCpf) => {
  const ga4 = {
    customPath: "/credito/imob/isolado/contratacao/simulacao/simular-credito",
    hashedCpf,
    implementationTeam: "imob:v01",
    detail: "btn:voltar-inicio",
    eventName: "click"
  }
  trackEvent({ ga4 })
}

const advanceGA4 = () => {
  const ga4 = {
    customPath: "/credito/imob/isolado/contratacao/simulacao/simular-credito",
    implementationTeam: "imob:v01",
    detail: "btn:simular",
    eventName: "click"
  }

  trackEvent({ ga4 })
}

const previousGA4 = () => {
  const ga4 = {
    customPath: "/credito/imob/isolado/contratacao/simulacao/simular-credito",
    implementationTeam: "imob:v01",
    detail: "btn:voltar",
    eventName: "click"
  }

  trackEvent({ ga4 })
}

const alert = (hashedCpf, error) => {
  const ga4 = {
    customPath: "/credito/imob/isolado/contratacao/simulacao/simular-credito",
    hashedCpf,
    implementationTeam: "imob:v01",
    eventName: "alert",
    description: "erro",
    detail: error.message
      ?.toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replaceAll(/ /g, "-")
      .replaceAll(/!/g, "")
  }

  trackEvent({ ga4 })
}

export {
  advance,
  pageview,
  backHome,
  qualified,
  advanceGA4,
  previousGA4,
  alert
}
