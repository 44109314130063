import { useEffect, useState } from "react"

import { formatters } from "@garantidos/utils"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "../../trackings"
import cardData from "./data"

const useResult = () => {
  const [financingOptions, setFinancingOptions] = useState([])
  const [financingOptionsText, setFinancingOptionsText] = useState("")
  const [isDetailsVisible, setIsDetailsVisible] = useState(false)
  const [financingDetailsSelected, setFinancingDetailsSelected] = useState(null)

  const { proposalData, isLoading } = useProposalContext()
  const { simulation_results } = proposalData

  const getFinancingData = (rawData) => {
    const options = []
    if (!rawData) return options
    rawData.forEach((data) => {
      const info = cardData.options.filter(
        (item) => item.feeType === data.fee_type
      )?.[0]

      if (
        proposalData.property_type === "COMERCIAL" &&
        data.fee_type === "POUPANCA"
      )
        return
      const {
        feeType,
        title,
        subText,
        diferential,
        extraInfosDescription,
        extraInfos,
        dataTestId
      } = info

      const {
        initial_installment,
        last_installment,
        effective_interest_rate,
        total_installments_value,
        yearly_effective_rate,
        total_effective_cost,
        cesh_annual
      } = data

      const yearlyEffectiveRate = `${formatters.floatToDisplayableNumber(
        yearly_effective_rate
      )}% a.a.`

      options.push({
        feeType,
        title,
        subText,
        diferential,
        extraInfosDescription,
        extraInfos,
        dataTestId,
        initialInstallment: formatters.toCurrency(initial_installment),
        lastInstallment: formatters.toCurrency(last_installment),
        yearlyEffectiveRate,
        listItems: [
          {
            label: "somatório das parcelas",
            value: formatters.toCurrency(total_installments_value)
          },
          {
            label: "custo efetivo total",
            value: `${formatters.floatToDisplayableNumber(
              effective_interest_rate
            )}% a.a.`
          },
          {
            label: "taxa de juros",
            value: yearlyEffectiveRate
          },
          {
            label: "seguro habitacional anual",
            value: `${formatters.floatToDisplayableNumber(cesh_annual)}% a.a.`
          }
        ],
        extraValues: [
          {
            label: "valor total financiado",
            value: formatters.toCurrency(total_effective_cost)
          }
        ]
      })
    })
    return options
  }

  const handleDetailsClick = (financingData) => {
    financingData.feeType === "PADRAO"
      ? trackers.seeDetailsCredit()
      : trackers.seeDetailsCreditPoupanca()

    setIsDetailsVisible(true)
    setFinancingDetailsSelected({ ...financingData })
  }

  useEffect(() => {
    const options = getFinancingData(simulation_results)
    setFinancingOptions(options)
    const text =
      options.length > 1
        ? `Você tem ${options.length} opções de financiamento:`
        : "Você tem 1 opção de financiamento:"
    setFinancingOptionsText(text)
  }, [simulation_results])

  return {
    financingOptions,
    financingOptionsText,
    isLoading,
    isDetailsVisible,
    setIsDetailsVisible,
    handleDetailsClick,
    financingDetailsSelected
  }
}

export default useResult
