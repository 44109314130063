import IconHome from "@mobi/ds/static/icons/filled/home.svg"
import IconVida from "@mobi/ds/static/icons/filled/vida.svg"

const insuranceInfos = [
  {
    Icon: IconVida,
    title: "MIP (Morte e Invalidez Permanente)",
    info: "O seguro de morte e invalidez permanente (MIP) tem como principal objetivo garantir a quitação ou amortização do saldo devedor de um financiamento imobiliário se houver um imprevisto coberto pela apólice. Geralmente, são oferecidas as coberturas de Morte (Natural e Acidental) e Invalidez Permanente."
  },
  {
    Icon: IconHome,
    title: "DFI (Danos Físicos ao Imóvel)",
    info: "Pagamento das despesas de recuperação dos dandos causados no imóvel decorrentes de incêndio ou explosão, inundação ou alagamento, desmoronamento parcial ou total das paredes e destelhamento, ocasionados por agentes externos ao imóvel, a exemplo de chuvas, ventos fortes, granizos."
  }
]

export default insuranceInfos
