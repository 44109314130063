import Likert from "@mobi/ds/components/Likert"

import useLikertComponent from "./hooks"

import "./style.scss"

const LikertComponent = () => {
  const { handleSubmitCallback, handleExperienceCallback } =
    useLikertComponent()

  return (
    <div className="likertComponent">
      <Likert
        submitOnClick={true}
        dataCallback={handleSubmitCallback}
        experienceClickCallback={handleExperienceCallback}
      />
    </div>
  )
}

export default LikertComponent
