import { Field } from "@garantidos/components"

import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"
import Modal from "@mobi/ds/components/Modal"
import Text from "@mobi/ds/components/Text"

import useFormGroupCreditLine from "./hooks"

import "./style.scss"

const FormGroupCreditLine = ({ form, isLoading }) => {
  const {
    fields,
    handleFeeTypeChange,
    handleAmortizationChange,
    feeTypeDisabled,
    amortizationDisabled,
    modalShow,
    handleModal,
    modalType,
    ModalContent,
    feeTypeExpensesMsg,
    amortizationExpensesMsg,
    hasMixAmortization
  } = useFormGroupCreditLine({ form })

  const { feeType, amortizationSystem, onlySacAmortizationSystem } = fields

  return (
    <div className="formGroupCreditLine">
      <Text as="h2" className="formGroupCreditLine__subtitle">
        linha de crédito
      </Text>
      <Button
        variant="action"
        className="formGroupCreditLine__actionButton--block"
        onClick={(e) => {
          handleModal(e, true, "aboutCreditLines")
        }}
        data-testid="credit_line_learn_more"
      >
        saiba mais
      </Button>
      <Field
        field={feeType}
        form={form}
        disabled={feeTypeDisabled || isLoading}
        onChange={(e) => handleFeeTypeChange(e.value)}
        data-testid={feeType.name}
      />
      <If
        condition={!!feeTypeExpensesMsg && !isLoading}
        renderIf={
          <>
            <div className="formGroupEasiness__divider" />
            <Text as="p" className="formGroupEasiness__cardInfo--warning">
              {feeTypeExpensesMsg}
            </Text>
          </>
        }
      />

      <Text as="h2" className="formGroupCreditLine__subtitle">
        sistema de amortização
      </Text>
      <Button
        variant="action"
        className="formGroupCreditLine__actionButton--block"
        onClick={(e) => {
          e.preventDefault()
          handleModal(e, true, "aboutAmortization")
        }}
        data-testid="amortization_learn_more"
      >
        entenda o que é e como funciona o sistema de amortização
      </Button>
      <Field
        field={
          hasMixAmortization ? amortizationSystem : onlySacAmortizationSystem
        }
        form={form}
        disabled={amortizationDisabled || isLoading}
        onChange={(e) => handleAmortizationChange(e.value)}
        data-testid={amortizationSystem.name}
      />
      <If
        condition={!!amortizationExpensesMsg}
        renderIf={
          <>
            <div className="formGroupEasiness__divider" />
            <Text as="p" className="formGroupEasiness__cardInfo--warning">
              {amortizationExpensesMsg}
            </Text>
          </>
        }
      />
      <Modal
        type={modalType}
        show={modalShow}
        onCloseClick={(e) => handleModal(e, false)}
      >
        <ModalContent onClickCallback={(e) => handleModal(e, false)} />
      </Modal>
    </div>
  )
}

export default FormGroupCreditLine
