import CardAccountHolder from "./components/CardAccountHolder"
import CardAccountNotHolder from "./components/CardAccountNotHolder"

const useAccompanyCard = (isAccountHolder) => {
  const getCardContent = () => {
    if (isAccountHolder) return CardAccountHolder

    return CardAccountNotHolder
  }

  return {
    CardContent: getCardContent()
  }
}

export default useAccompanyCard
