import { useEffect, useState } from "react"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

const useDetailsList = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const isMobile = useMediaQuery("mobile")

  useEffect(() => {
    if (!isMobile) {
      setIsExpanded(false)
    }
  }, [isMobile])

  const toggleDetailsList = () => {
    setIsExpanded(!isExpanded)
  }

  const getButtonProps = (expanded) => {
    const props = {
      "aria-expanded": expanded,
      "aria-label": expanded ? "Retrair lista" : "Expandir lista",
      "aria-controls": "detailsList"
    }
    return props
  }

  return {
    isMobile,
    toggleDetailsList,
    isExpanded,
    detailsListExpansionClass: isExpanded ? "detailsList__list--expanded" : "",
    buttonIconExpansionClass: isExpanded ? "detailsList__icon--flipped" : "",
    buttonProps: getButtonProps(isExpanded)
  }
}

export default useDetailsList
