import { useProposalContext } from "contexts/proposal"

import * as trackers from "../../trackings"

const useFinishedComplementaryText = (isAccountHolder) => {
  const {
    cpfData,
    proposalData: { proposal_integration_number }
  } = useProposalContext()

  const { hashedCpf } = cpfData
  //TODO: Colocar isso em um arquivo data
  const getText = () => {
    if (isAccountHolder)
      return "Guarde esse número, acompanhe o status da sua proposta e adiante os documentos necessários para a sua contratação clicando no botão abaixo:"

    return "Quer taxas diferenciadas e todos os benefícios de ser cliente Itaú? Aproveite esse momento para abrir sua conta, pois será necessário durante o processo de contratação. "
  }

  //TODO: Colocar isso em um arquivo data
  const getButtonInfo = () => {
    if (isAccountHolder) {
      return {
        label: "acompanhar a sua proposta",
        href: "https://www.itau.com.br/emprestimos-financiamentos/credito-imobiliario/acompanhar-proposta"
      }
    }

    return {
      label: "abra sua conta",
      href: "https://www.itau.com.br/abrir-conta/?utm_source=st&utm_medium=nd&utm_campaign=st-conv-nd-st-nd-all&utm_content=nd-nd-nd-iacnta-nd-pfaqu-MT00000831-imobproposta&utm_term=imob-x-status-marketplace&utm_sou=imob&utm_med=x&utm_camp=status&utm_cont=marketplace&utm_ter=15"
    }
  }

  const handleTrackEvent = () => {
    if (isAccountHolder) {
      trackers.accountNotHolder(hashedCpf, proposal_integration_number)
    } else {
      trackers.openAccount(hashedCpf, proposal_integration_number)
    }
  }

  return {
    text: getText(),
    buttonInfo: getButtonInfo(),
    handleTrackEvent
  }
}

export default useFinishedComplementaryText
