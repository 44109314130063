import { formatters } from "@garantidos/utils"

import removeSpecialChars from "@mobi/utils/formatters/removeSpecialChars"

const {
  removeCharsExceptNumbers,
  toPercent,
  toCurrency,
  toDate,
  monthsToYears
} = formatters

const validatorsDictionary = {
  wordsLengthMoreThan: {
    rule: ({ conditionValue }) => {
      return (value) => {
        if (!value) return true
        let wordsArr = value.trim().split(/\s+|\s/)
        return wordsArr.every((word) => word.length > conditionValue)
      }
    },
    message: () => "Preencha o nome e sobrenome corretamente"
  },

  minAge: {
    rule: ({ conditionValue }) => {
      return (value) => !isUnderAge(value, conditionValue)
    },
    message: ({ conditionValue = 18 }) =>
      `A idade mínima é de ${conditionValue} anos`
  },

  distinctDocument: {
    rule: ({ proposalData: { cpf } }) => {
      return (value) => {
        return removeSpecialChars(value) !== cpf
      }
    },
    message: () => "CPF do participante não pode ser o mesmo do cliente"
  },

  maxFinancingTerm: {
    rule: ({ conditionValue, proposalData: { property_type } }) => {
      return (value) => {
        const condition = conditionValue[property_type]
        if (!condition || !property_type) return true
        return maxYears({ value, conditionValue: condition })
      }
    },
    message: ({ conditionValue, proposalData: { property_type } }) => {
      const condition = conditionValue[property_type]
      if (!condition) return ""
      return `Prazo máximo de financiamento é de ${condition} anos`
    }
  },

  greaterThanZero: {
    rule: () => {
      return (value) => value > 0
    },
    message: () => "Valor da renda deve ser maior que R$ 0,00"
  },

  requiredYears: {
    rule: () => {
      return (value) => value > 0
    },
    message: () => "Informe o prazo de financiamento a partir de 1 ano"
  },

  confirmEmail: {
    rule: ({ proposalData: { email } }) => {
      return (value) => value === email
    },
    message: () => "Confirmação de e-mail deve estar igual ao campo de e-mail"
  },

  propertyValueMinMax: {
    rule: ({ conditionValue, proposalData: { property_type } }) => {
      return (value) => {
        const condition = conditionValue[property_type]
        if (!condition) return true
        const { min, max } = condition
        return value >= min && value <= max
      }
    },
    message: ({ conditionValue, proposalData: { property_type } }) => {
      const condition = conditionValue[property_type]
      if (!condition) return ""
      const { min, max } = condition
      const minCurrency = toCurrency(min)
      const maxCurrency = toCurrency(max)
      return `Preencha um valor entre ${minCurrency} e ${maxCurrency}`
    }
  },

  admissionDateLead: {
    rule: ({ conditionValue = 14, proposalData: { birthdate } }) => {
      return (value) => {
        if (!birthdate) return true
        const _birthdate = toDate(birthdate) || 0
        if (!_birthdate) return true
        return getAge(_birthdate) - getAge(value) >= conditionValue
      }
    },
    message: ({ conditionValue = 14 }) =>
      `Data de admissão deve ser a partir de ${conditionValue} anos de idade`
  },

  admissionDatePartner: {
    rule: ({ conditionValue = 14, proposalData: { partner_birthdate } }) => {
      return (value) => {
        if (!partner_birthdate) return true
        const _birthdate = toDate(partner_birthdate) || 0
        if (!_birthdate) return true
        return getAge(_birthdate) - getAge(value) >= conditionValue
      }
    },
    message: ({ conditionValue = 14 }) =>
      `Data de admissão deve ser a partir de ${conditionValue} anos de idade`
  },

  maxIncome: {
    rule: ({ conditionValue }) => {
      return (value) => value <= conditionValue
    },
    message: ({ conditionValue }) =>
      `Valor da renda não pode ser maior que ${toCurrency(conditionValue)}`
  },

  minFinancingValue: {
    rule: ({
      conditionValue,
      proposalData: { property_type, property_value }
    }) => {
      return (value) => {
        const condition = conditionValue[property_type]
        if (!condition) return true
        return minFinancing({
          conditionValue: condition,
          propertyValue: property_value,
          inputValue: value
        })
      }
    },
    message: ({ conditionValue, proposalData: { property_type } }) => {
      const condition = conditionValue[property_type]
      if (!condition) return ""
      return `Valor a ser financiado deve ser maior ou igual a ${toCurrency(
        condition
      )}`
    }
  },

  inputValueLowerThanPropertyValue: {
    rule:
      ({ proposalData: { property_value } }) =>
      (value) =>
        value <= property_value,
    message: () => "O valor da entrada não deve ser maior que o valor do imóvel"
  },

  inputValue: {
    rule: ({
      conditionValue,
      proposalData: { property_type, property_value }
    }) => {
      return (value) => {
        const condition = conditionValue[property_type]
        if (!condition) return true
        if (!property_value) return true
        const { minInputRatio, minFinancingValue } = condition
        const hasMinInput = minInput({
          propertyValue: property_value,
          inputValue: value,
          ratio: minInputRatio
        })
        const hasMaxInput = maxInput({
          propertyValue: property_value,
          inputValue: value,
          minFinancingValue
        })
        return hasMinInput && hasMaxInput
      }
    },
    message: ({
      conditionValue,
      rules: { minFinancingValue },
      proposalData: { property_type, property_value }
    }) => {
      const condition = conditionValue[property_type]
      if (!condition) return ""
      const minFinancing = minFinancingValue[property_type]
      const errorMessage = getFinancingRatioErrorMessage({
        propertyType: property_type,
        propertyValue: property_value,
        minFinancingValue: minFinancing,
        financingRatio: condition
      })
      return errorMessage
    }
  },

  finacingTermWithAge: {
    rule: ({ conditionValue = 80.5, proposalData: { birthdate } }) => {
      return (value) => {
        value = parseFloat(value)
        const financingTerm = value || 0
        const _birthdate = toDate(birthdate) || 0
        if (!_birthdate) return true
        const age = getAge(_birthdate) || conditionValue + 1
        const isLowerThanLimit = financingTerm + age <= conditionValue || !age
        return isLowerThanLimit
      }
    },
    message: ({ conditionValue = 80.5 }) => {
      return getFinancingTermWithAgeMessage(conditionValue)
    }
  },

  finacingTermWithAgePartner: {
    rule: ({ conditionValue = 80.5, proposalData: { financing_term } }) => {
      return (value) => {
        if (!financing_term) return true
        const financingTerm = monthsToYears(financing_term) || 0
        const partnerAge = getAge(value) || conditionValue + 1
        const isLowerThanLimit = financingTerm + partnerAge <= conditionValue
        return isLowerThanLimit
      }
    },
    message: ({ conditionValue = 80.5 }) => {
      return getFinancingTermWithAgeMessage(conditionValue)
    }
  },

  financingTermByAmortizationSystem: {
    rule: ({
      conditionValue = { SAC: 1, MIX: 3 },
      proposalData: { amortization_system }
    }) => {
      return (value) => {
        if (!amortization_system) return true
        const condition = conditionValue[amortization_system]
        if (!condition) return true
        const financingTerm = value || 0
        const result = financingTerm >= condition
        return result
      }
    },
    message: ({
      conditionValue = { SAC: 1, MIX: 3 },
      proposalData: { amortization_system }
    }) => {
      const condition = conditionValue[amortization_system]
      if (!condition) return true
      const minFinancingTerm = `${condition} ${
        condition === 1 ? "ano" : "anos"
      }`
      const msg = `Para o sistema de amortização escolhido, o prazo mínimo é de ${minFinancingTerm}`
      return msg
    }
  },

  domainMultipleChar: {
    rule: () => (value) => {
      if (!value) return true
      const reg = /@[a-zA-Z0-9]\b/gm
      return !value.match(reg)
    },
    message: () => "email inválido"
  }
}

export default validatorsDictionary

//UTILS

const maxYears = ({ value, conditionValue }) => value <= conditionValue

const getAge = (dateString) => {
  if (!dateString) return 0
  const [day, month, year] = dateString.split("/")
  const date = `${month}/${day}/${year}`

  const today = new Date()
  const birthDate = new Date(date)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  let decimalMonths = parseFloat(m / 12)
  if (today.getMonth() - month > 0) {
    decimalMonths += 0.01
  }
  const d = today.getDay() - birthDate.getDate()
  let decimalDays = parseFloat(d / 12 / 30)
  if (today.getDate() - day > 0) {
    decimalDays += 0.01
  }

  return age + decimalMonths + decimalDays
}

export const minFinancing = ({ conditionValue, propertyValue, inputValue }) => {
  if (!propertyValue || !inputValue) return true
  const hasMinFinancingValue = propertyValue - inputValue >= conditionValue
  return hasMinFinancingValue
}

export const isUnderAge = (birthday, age = 18) => {
  const instances = birthday.split("/")
  const [day, month, year] = instances
  return (
    new Date(Number(year) + age, Number(month) - 1, day, "00", "00", "00") >
    new Date()
  )
}

const minInput = ({ propertyValue, inputValue, ratio }) => {
  const hasMinInput = inputValue >= propertyValue * ratio
  return hasMinInput
}

const maxInput = ({ propertyValue, inputValue, minFinancingValue }) => {
  const hasMaxInput = inputValue <= propertyValue - minFinancingValue
  return hasMaxInput
}

const getFinancingRatioErrorMessage = ({
  propertyType,
  propertyValue,
  minFinancingValue,
  financingRatio
}) => {
  const { minInputRatio } = financingRatio
  if (propertyType === "RESIDENCIAL") {
    const maxInputRatio = 1 - minInputRatio
    const msg = `Valor de entrada deve ser entre ${toPercent(
      minInputRatio
    )} e ${toPercent(maxInputRatio)} do valor do imóvel`
    return msg
  }
  const minInputValue = propertyValue * minInputRatio
  const maxInputValue = propertyValue - minFinancingValue
  const msg = `O valor de entrada deve ser entre ${toCurrency(
    minInputValue
  )} e ${toCurrency(maxInputValue)}`
  return msg
}

const getFinancingTermWithAgeMessage = (conditionValue) => {
  const years = Math.floor(conditionValue)
  const months = (conditionValue * 12) % 12
  const textYears = years ? `${years} ${years === 1 ? "ano" : "anos"}` : ""
  const textMonths = months ? `${months} ${months === 1 ? "mês" : "meses"}` : ""
  const textConnector = years && months ? " e " : ""
  const msg = `A soma da idade com o prazo de financiamento não pode ultrapassar ${textYears}${textConnector}${textMonths}`
  return msg
}
