import { useEffect, useState } from "react"

import { useStepTransition } from "@garantidos/hooks"
import { resetFocus } from "@garantidos/utils/js/accessibility"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "./trackings"

const useSimulationResult = () => {
  const [modalShow, setModalShow] = useState(false)
  const [fieldsToShow, setFieldsToShow] = useState({})
  const isMobile = useMediaQuery("mobile")
  const {
    stepInfo,
    proposalData,
    sendProposalData,
    errorMessage,
    setErrorMessage,
    errorHandler,
    isLoading,
    cpfData,
    setIsStepVisible
  } = useProposalContext()

  const { stepNavigator } = useStepTransition(setIsStepVisible)

  const { currentStep, nextStep } = stepInfo
  const { hashedCpf } = cpfData

  const handleNextStep = async (fee_type) => {
    if (isLoading) return
    try {
      const payload = {
        fee_type
      }
      await sendProposalData({ payload })
      fee_type === "PADRAO"
        ? trackers.advanceCredit()
        : trackers.advanceCreditPoupanca()
      trackers.advanceGA(hashedCpf)

      stepNavigator("next", nextStep)
    } catch (error) {
      trackers.alert(hashedCpf, error)
      errorHandler(error)
    }
  }

  const toggleModal = (state = null) => {
    if (state) trackers.editGA4()
    setModalShow(state)
  }

  const updateFieldsToShow = () => {
    const { property_value, input_value, financing_term, birthdate, insurer } =
      proposalData
    setFieldsToShow({
      property_value,
      input_value,
      financing_term,
      birthdate,
      insurer
    })
  }

  useEffect(() => {
    updateFieldsToShow()
    trackers.pageview(hashedCpf)
    trackers.simulationGA4(hashedCpf)

    const backButton = document.querySelector(".ds-header__button-back")
    backButton.onclick = () => trackers.backHome(hashedCpf)

    resetFocus()
  }, [])

  return {
    modalShow,
    isMobile,
    currentStep,
    handleNextStep,
    toggleModal,
    modalType: isMobile ? "center" : "pop",
    errorMessage,
    setErrorMessage,
    fieldsToShow,
    updateFieldsToShow
  }
}

export default useSimulationResult
