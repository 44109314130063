import { useContext } from "react"

import { getProposalContext } from "@garantidos/contexts"

import * as stepsData from "../pages/Proposal/steps"

const { ProposalContext, ProposalDataProvider } = getProposalContext({
  stepsData
})

const useProposalContext = () => useContext(ProposalContext)

const useProposalDataProvider = () => ProposalDataProvider

export { useProposalContext, useProposalDataProvider, ProposalContext }
