import { Fragment } from "react"

import { Field } from "@garantidos/components"

import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"

import linksEnum from "pages/Proposal/constants/linksEnum"

import useFormGroupPartnerAddress from "./hooks"

import "./style.scss"

const FormGroupPartnerAddress = ({ form, isLoading }) => {
  const { setValue } = form
  const { fields, onCepResultCallback } = useFormGroupPartnerAddress(setValue)
  return (
    <>
      {Object.values(fields).map((field, index) => (
        <If
          condition={field.type === "cep"}
          renderIf={
            <Fragment key={index}>
              <Field
                field={field}
                form={form}
                onResult={onCepResultCallback}
                data-testid={field.name}
                disabled={isLoading}
              />
              <Button
                variant="action"
                tagName="a"
                href={linksEnum.BUSCA_CEP}
                target="_blank"
                rel="noreferrer"
                className="formGroupPartnerAddress__link"
              >
                não sei o cep
              </Button>
            </Fragment>
          }
          renderElse={
            <Field
              key={index}
              field={field}
              form={form}
              data-testid={field.name}
              disabled={isLoading}
            />
          }
        />
      ))}
    </>
  )
}

export default FormGroupPartnerAddress
