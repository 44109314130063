const focusInElement = (selector, options = {}) => {
  const timeout = options.timeout || 100
  const preventScroll = options.preventScroll || false

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const el = document.querySelector(selector)
      console.log(selector, el)
      if (el) {
        el.focus({ preventScroll })
        return resolve(el)
      }
      return reject(el)
    }, timeout)
  })
}

const resetFocus = () => {
  const isClient = typeof window !== "undefined"
  if (!isClient) return
  const firstLink = document.querySelector(".ds-header__logo > a")
  if (firstLink) firstLink.focus()
}

export { focusInElement, resetFocus }
