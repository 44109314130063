import { useEffect, useState } from "react"

import {
  useForm,
  usePayloadFormatters,
  useStepTransition
} from "@garantidos/hooks"
import { formatters, removeSpecialCharsFromAddress } from "@garantidos/utils"
import { resetFocus } from "@garantidos/utils/js/accessibility"

import validatorsDictionary from "pages/Proposal/hooks/validators"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { fields } from "./fields"
import * as trackers from "./trackings"

const useAddress = () => {
  const { form } = useForm({
    fields,
    context: ProposalContext,
    validatorsDictionary
  })
  const {
    stepInfo,
    cpfData,
    fetchProposalData,
    sendProposalData,
    errorMessage,
    setErrorMessage,
    errorHandler,
    isLoading,
    setIsStepVisible,
    proposalData
  } = useProposalContext()
  const { stepNavigator } = useStepTransition(setIsStepVisible)
  const { formatSendPayload } = usePayloadFormatters(fields)

  const { hashedCpf } = cpfData

  const { setValue, clearErrors, resetField } = form
  const { previousStep, nextStep } = stepInfo

  useEffect(() => {
    trackers.pageview(hashedCpf)

    const backButton = document.querySelector(".ds-header__button-back")
    backButton.onclick = () => trackers.backHome(hashedCpf)

    resetFocus()
  }, [])

  const [forbidFirstTime, setForbidFirstTime] = useState(proposalData.cep)

  const onCepResult = (address) => {
    if (forbidFirstTime) {
      setForbidFirstTime(false)
      return
    }
    const setValueOptions = {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    }
    const stateFormatter = formatters.fetchFormatters[fields.state.fetchFormat]

    const stateValue = stateFormatter({
      value: address?.uf ? address.uf.toUpperCase() : "",
      options: fields.state.options
    })

    setValue(
      "street",
      removeSpecialCharsFromAddress(address.street),
      setValueOptions
    )
    setValue(
      "neighborhood",
      removeSpecialCharsFromAddress(address.neighborhood),
      setValueOptions
    )
    clearErrors("state")
    setValue("state", stateValue, setValueOptions)
    resetField("state", {
      defaultValue: stateValue
    })
    setValue(
      "city",
      removeSpecialCharsFromAddress(address.city),
      setValueOptions
    )
  }

  const onSubmitCallback = async (formData) => {
    if (isLoading) return
    try {
      const payload = formatSendPayload(formData)
      await sendProposalData({ payload })

      trackers.advance()
      stepNavigator("next", nextStep)
    } catch (error) {
      trackers.alert(hashedCpf, error)
      errorHandler(error)
    }
  }

  const handlePrevStep = async () => {
    if (isLoading) return
    try {
      await fetchProposalData({ step: previousStep, recaptcha: true })
      trackers.previousGA4()
      stepNavigator("prev", previousStep)
    } catch (error) {
      trackers.alert(hashedCpf, error)
      errorHandler(error)
    }
  }

  return {
    handlePrevStep,
    onSubmitCallback,
    onCepResult,
    fields,
    form,
    errorMessage,
    setErrorMessage,
    isLoading
  }
}

export default useAddress
