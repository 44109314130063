import { useEffect, useState } from "react"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "../../trackings"
import { fields } from "./fields"

const useFormGroupEasines = () => {
  const {
    checkFinanceExpense,
    proposalData,
    errorHandler,
    isLoading,
    cpfData
  } = useProposalContext()

  const { hashedCpf } = cpfData

  const [financeExpensesMsg, setFinanceExpensesMsg] = useState("")
  const [showHomeInsuranceLegal, setShowHomeInsuranceLegal] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [eventLabel, setEventLabel] = useState("")

  const trackingInfo = {
    event: {
      ga: {
        label: `BTN:Simulador:${eventLabel}`,
        action: "Simulador",
        hashedCpf
      }
    }
  }

  useEffect(() => {
    if (eventLabel === "") return
    trackers.financeExpense(eventLabel, hashedCpf)
  }, [eventLabel])

  const handleFinanceExpensesInput = async (value) => {
    value
      ? setEventLabel("FinanciarDespesasITBISim")
      : setEventLabel("FinanciarDespesasITBINao")

    if (!value) {
      setFinanceExpensesMsg("")
      return
    }
    if (isLoading) return

    try {
      const financeExpenseResponse = await checkFinanceExpense({
        recaptcha: true
      })

      if (financeExpenseResponse) {
        setFinanceExpensesMsg(financeExpenseResponse)
      }
    } catch (error) {
      errorHandler(error)
      setFinanceExpensesMsg("")
    }
  }

  useEffect(() => {
    handleFinanceExpensesInput(proposalData.finance_expenses)
  }, [])

  const handleHomeInsurance = (value) => {
    trackers.homeInsurance(value)
    setShowHomeInsuranceLegal(value)
  }

  const handleShowMore = () => {
    if (!showMore) trackers.showMore()
    setShowMore(!showMore)
  }

  const trackHomeInsuranceLink = () => {
    trackers.homeInsuranceLink()
  }

  const trackHomeInsuranceLegal = () => {
    trackers.homeInsuranceLegal()
  }

  return {
    fields,
    handleFinanceExpensesInput,
    financeExpensesMsg,
    showHomeInsuranceField: proposalData.property_type === "RESIDENCIAL",
    showHomeInsuranceLegal,
    handleHomeInsurance,
    showMore,
    handleShowMore,
    trackHomeInsuranceLink,
    trackHomeInsuranceLegal
  }
}

export default useFormGroupEasines
