import { Icon } from "@garantidos/components"

import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"
import useMediaQuery from "@mobi/hooks/useMediaQuery"

import Img from "components/img"

import "./style.scss"

import infosBox from "./data"

const ExtraInfos = () => {
  const isMobile = useMediaQuery("mobile")

  return (
    <div
      className="extraInfos"
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="500"
    >
      <If
        condition={!isMobile}
        renderIf={
          <Img
            file="simulation-result-image"
            className="extraInfos__image"
            lazy={false}
          />
        }
      />
      {infosBox.map(({ icon, title, subText, _uid }) => (
        <div key={_uid} className="extraInfos__card">
          <Icon icon={icon} type="filled" className="extraInfos__cardIcon" />
          <div className="extraInfos__card-content">
            <Text as="p" className="extraInfos__cardTitle">
              {title}
            </Text>
            <Text as="p" className="extraInfos__cardDescription">
              {subText}
            </Text>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ExtraInfos
