import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const DEFAULT_PAGE_EVENT = "ErroInstabilidade"

const contactTypeParser = {
  PHONE: {
    gaText: "ContatoTelefone"
  },
  WHATSAPP: {
    gaText: "ContatoWhatsApp"
  }
}

const pageview = (pageEvents, errorCode, fromStep) => {}

const formErrored = (fromStep) => {}

const buttonCallback = (pageEvents, errorCode, trackEventObject, fromStep) => {}

const dropFlow = ({
  pageEvents,
  errorCode,
  contactType,
  stepContexts,
  fromStep
}) => {}

const formSubmit = (errorCode, stepContexts) => {}

const elementClicked = (errorCode, stepContexts, contactType) => {}

export {
  pageview,
  formErrored,
  buttonCallback,
  dropFlow,
  formSubmit,
  elementClicked
}
