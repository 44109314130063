const data = [
  {
    icon: "computador",
    title:
      "Menu > Crédito > Ver mais > Crédito Imobiliário > Acompanhe sua proposta",
    subText:
      "Na internet, acesse: Menu, crédito, ver mais, crédito imobiliário, acompanhe sua proposta"
  },
  {
    icon: "celular-card",
    title:
      "Menu produtos > Financiamento de Imóvel > Minhas Propostas > Acompanhar",
    subText:
      "No app do itaú, acesse: Menu produtos, financiamento de imóveis, minhas propostas, acompanhar"
  }
]

export default data
