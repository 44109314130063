import { useEffect } from "react"

import { resetFocus } from "@garantidos/utils/js/accessibility"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

import { useFlags } from "pages/Proposal/hooks/useFlags"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "./trackings"

const useFinished = () => {
  const isMobile = useMediaQuery("tablet")
  const {
    proposalData: { proposal_integration_number },
    cpfData: { hashedCpf }
  } = useProposalContext()
  const proposalIntegrationNumber = proposal_integration_number

  const { hasAccount } = useFlags()

  const trackDsBackButton = () => {
    trackers.backHome(hashedCpf)
  }

  useEffect(() => {
    if (proposalIntegrationNumber) {
      trackers.pageview(hashedCpf, proposalIntegrationNumber)
      trackers.proposal(hashedCpf, proposalIntegrationNumber)
    }
  }, [proposalIntegrationNumber])

  useEffect(() => {
    const back = document.getElementById("main-logo")
    back.onclick = () => trackDsBackButton()

    resetFocus()
  }, [])

  const handleNextStepTrack = () => {
    trackers.nextSteps(hashedCpf, proposalIntegrationNumber)
  }

  return {
    isMobile,
    proposalIntegrationNumber,
    hasAccount,
    handleNextStepTrack
  }
}

export default useFinished
