const data = {
  title: "Confira aqui quais são os proximos passos:",
  steps: [
    {
      title: "Envio da Proposta",
      text: "Guarde o número da sua proposta. Adiante os documentos necessários para sua contratação.",
      status: "done"
    },
    {
      title: "Análise de Crédito",
      text: "Nessa fase podemos solicitar alguns documentos para ajudar na aprovação do valor.",
      status: "ongoing"
    },
    {
      title: "Análise de documentos e avaliação do Imóvel",
      text: "Importante que você já tenha escolhido seu imóvel.",
      status: "open"
    },
    {
      title: "Confirmação dos dados e valores",
      text: "",
      status: "open"
    },
    {
      title: "Emissão e registro do Contrato",
      text: "",
      status: "open"
    },
    {
      title: "Liberação do Crédito",
      text: "Pode demorar até 3 dias úteis após o retorno do contrato registrado em cartório.",
      status: "open"
    }
  ],
  subTextBold: "Para um acompanhamento mais detalhado, clique no botão abaixo:",
  subTextNormal:
    "Insira o número da proposta e o seu CPF para consultar. Se você for 						correntista, pode acompanhar também pelo App Itaú.",
  buttonText: "acompanhar proposta",
  buttonLink:
    "https://www.itau.com.br/emprestimos-financiamentos/credito-imobiliario/acompanhar-proposta"
}

export default data
