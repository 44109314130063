import { useEffect, useState } from "react"

import validatorsDictionary from "pages/Proposal/hooks/validators"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import * as trackers from "../../trackings"
import { fields } from "./fields"

const useFormGroupAccountHolder = () => {
  const { cpfData } = useProposalContext()

  const { hashedCpf } = cpfData

  const [eventLabel, setEventLabel] = useState("")

  const handleAccountHolderChange = (value) => {
    value === "sim"
      ? setEventLabel("ContaItauSim")
      : setEventLabel("ContaItauNão")
  }

  useEffect(() => {
    if (eventLabel === "") return
    trackers.accountHolder(hashedCpf, eventLabel)
  }, [eventLabel])

  return {
    fields,
    context: ProposalContext,
    validatorsDictionary,
    handleAccountHolderChange
  }
}

export default useFormGroupAccountHolder
