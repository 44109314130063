import { Form, HousingAlert, Sender } from "@garantidos/components"
import { Otp } from "@garantidos/components"

import useSms from "./hooks"

import "./style.scss"

const Sms = () => {
  const {
    telephone,
    otpToken,
    onFormSubmit,
    onSubmitCallback,
    onOtpInvalid,
    handlePrevStep,
    isLoading,
    isValid,
    setIsValid,
    form,
    errorMessage,
    setErrorMessage
  } = useSms()

  return (
    <div className="leadSms">
      <Form onSubmit={onFormSubmit} form={form}>
        <HousingAlert
          type="error"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Otp
          otpToken={otpToken}
          phoneNumber={telephone}
          onContinue={onSubmitCallback}
          onInvalid={onOtpInvalid}
          setIsValid={setIsValid}
        />
        <Sender
          onPrev={handlePrevStep}
          isNextDisabled={!isValid}
          id="submit-sms-step-btn"
          nextLabel="avançar"
          data_testid="submit"
          isLoading={isLoading}
        />
      </Form>
    </div>
  )
}

export default Sms
