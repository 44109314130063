import { UF_OPTIONS } from "../../constants/dropdownOptions"

export const fields = {
  cep: {
    type: "cep",
    name: "cep",
    label: "CEP",
    sendFormat: "cep"
  },
  street: {
    type: "text",
    name: "street",
    label: "endereço"
  },
  neighborhood: {
    type: "text",
    name: "neighborhood",
    label: "bairro"
  },
  state: {
    type: "select",
    name: "state",
    label: "estado",
    placeholder: "selecione",
    options: UF_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select"
  },
  city: {
    type: "text",
    name: "city",
    label: "cidade"
  }
}
