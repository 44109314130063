import { Field, Form, HousingAlert, Sender } from "@garantidos/components"

import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import linksEnum from "pages/Proposal/constants/linksEnum"

import useAddress from "./hooks"

import "./style.scss"

const Address = () => {
  const {
    handlePrevStep,
    onSubmitCallback,
    onCepResult,
    fields,
    form,
    errorMessage,
    setErrorMessage,
    isLoading
  } = useAddress()

  const { cep, street, neighborhood, state, city } = fields
  const { isValid } = form

  return (
    <div className="proposalAddress">
      <div className="proposalAddress__formWrapper">
        <HousingAlert
          type="error"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Text
          as="h1"
          size="lg"
          className="proposalAddress__title"
          data-aos="fade-left"
          data-aos-delay="200"
          data-aos-duration="800"
        >
          endereço
        </Text>
        <Text
          as="p"
          size="md"
          className="proposalAddress__subtitle"
          data-aos="fade-left"
          data-aos-delay="300"
          data-aos-duration="800"
        >
          Conte-nos um pouco sobre onde você mora atualmente:
        </Text>
        <Form
          onSubmit={onSubmitCallback}
          form={form}
          data-aos="fade-left"
          data-aos-delay="400"
          data-aos-duration="800"
        >
          <Field
            field={cep}
            onResult={onCepResult}
            form={form}
            data-testid={cep.name}
            disabled={isLoading}
          />
          <Button
            variant="action"
            tagName="a"
            href={linksEnum.BUSCA_CEP}
            target="_blank"
            rel="noreferrer"
            className="formGroupPartnerAddress__link"
          >
            não sei o cep
          </Button>
          <Field
            field={street}
            form={form}
            data-testid={street.name}
            disabled={isLoading}
          />
          <Field
            field={neighborhood}
            form={form}
            data-testid={neighborhood.name}
            disabled={isLoading}
          />
          <Field
            field={state}
            form={form}
            data-testid={state.name}
            disabled={isLoading}
          />
          <Field
            field={city}
            form={form}
            data-testid={city.name}
            disabled={isLoading}
          />
          <Sender
            showPrev
            onPrev={handlePrevStep}
            isNextDisabled={!isValid}
            data_testid="submit"
            isLoading={isLoading}
          />
        </Form>
      </div>
    </div>
  )
}

export default Address
