import {
  AMORTIZATION_SYSTEM_OPTIONS,
  FEE_TYPE_OPTIONS
} from "pages/Proposal/constants/dropdownOptions"

export const fields = {
  feeType: {
    type: "select",
    name: "fee_type",
    label: "escolha a linha",
    placeholder: "selecione",
    options: FEE_TYPE_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select"
  },
  amortizationSystem: {
    type: "select",
    name: "amortization_system",
    label: "escolha o sistema",
    placeholder: "selecione",
    options: AMORTIZATION_SYSTEM_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select"
  },
  onlySacAmortizationSystem: {
    type: "select",
    name: "amortization_system",
    label: "escolha o sistema",
    placeholder: "selecione",
    options: [
      {
        label: "SAC (Sistema de Amortização Constante)",
        value: "SAC"
      }
    ],
    sendFormat: "select",
    fetchFormat: "select"
  }
}
