import { useEffect, useState } from "react"

import {
  useForm,
  usePayloadFormatters,
  useStepTransition
} from "@garantidos/hooks"
import { resetFocus } from "@garantidos/utils/js/accessibility"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

import validatorsDictionary from "pages/Proposal/hooks/validators"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { fields } from "./fields"
import * as trackers from "./trackings"

const useSimulation = () => {
  const [insuranceModalShow, setInsuranceModalShow] = useState(false)

  const { form } = useForm({
    fields,
    context: ProposalContext,
    validatorsDictionary
  })
  const { setValue } = form

  const {
    stepInfo,
    cpfData,
    fetchProposalData,
    sendProposalData,
    proposalData,
    errorMessage,
    setErrorMessage,
    errorHandler,
    isLoading,
    setIsStepVisible
  } = useProposalContext()
  const { stepNavigator } = useStepTransition(setIsStepVisible)
  const { formatSendPayload } = usePayloadFormatters(fields)
  const isMobile = useMediaQuery("mobile")
  const maxFinancingTerm =
    proposalData.property_type === "COMERCIAL" ? "20" : "35"

  const { previousStep, nextStep } = stepInfo
  const { hashedCpf } = cpfData

  useEffect(() => {
    trackers.pageview(hashedCpf)

    const backButton = document.querySelector(".ds-header__button-back")
    backButton.onclick = () => trackers.backHome(hashedCpf)

    resetFocus()
  }, [])

  const onSubmitCallback = async (formData) => {
    if (isLoading) return
    try {
      trackers.advanceGA4()

      const payload = formatSendPayload(formData)

      handleQualifiedTracking(payload)

      await sendProposalData({ payload })

      stepNavigator("next", nextStep)
    } catch (error) {
      errorHandler(error)
      trackers.alert(hashedCpf, error)
    }
  }

  const handlePrevStep = async () => {
    if (isLoading) return
    try {
      await fetchProposalData({ step: previousStep, recaptcha: true })
      trackers.previousGA4()
      stepNavigator("prev", previousStep)
    } catch (error) {
      errorHandler(error)
    }
  }

  const toggleInsuranceModal = (state) => {
    if (state) trackers.insuranceModal()
    setInsuranceModalShow(state)
  }

  const handleBirthDate = () => {
    if (proposalData.birthdate?.length !== 24) return

    const age =
      (new Date() - new Date(proposalData.birthdate)) /
      1000 /
      60 /
      60 /
      24 /
      365

    if (age + proposalData.financing_term / 12 >= 80.5) {
      const newFinancing =
        Math.floor(80.5 - age) > maxFinancingTerm
          ? maxFinancingTerm
          : Math.floor(80.5 - age)

      if (newFinancing >= 1 && newFinancing <= maxFinancingTerm)
        setValue("financing_term", newFinancing.toString())
    }
  }

  const handleType = (e) => {
    setValue("financing_term", e === "RESIDENCIAL" ? "35" : "20")
  }

  const handleQualifiedTracking = (payload) => {
    trackers.qualified(payload)
  }

  return {
    handlePrevStep,
    onSubmitCallback,
    fields,
    form,
    insuranceModalShow,
    insuranceModalType: isMobile ? "center" : "pop",
    toggleInsuranceModal,
    errorMessage,
    setErrorMessage,
    maxFinancingTerm,
    handleType,
    handleBirthDate,
    isLoading
  }
}

export default useSimulation
