import { Field, Form, HousingAlert, Sender } from "@garantidos/components"

import Button from "@mobi/ds/components/Button"
import Modal from "@mobi/ds/components/Modal"
import Text from "@mobi/ds/components/Text"

import AboutInsurance from "./components/AboutInsurance"
import AttentionBox from "./components/AttentionBox"

import useSimulation from "./hooks"

import "./style.scss"

const Simulation = () => {
  const {
    handlePrevStep,
    onSubmitCallback,
    fields,
    form,
    toggleInsuranceModal,
    insuranceModalType,
    insuranceModalShow,
    errorMessage,
    setErrorMessage,
    maxFinancingTerm,
    handleType,
    handleBirthDate,
    isLoading
  } = useSimulation()

  const { isValid } = form
  const {
    propertyType,
    propertyValue,
    inputValue,
    birthdate,
    financingTerm,
    hasProperty,
    timeAcquireProperty,
    insurer
  } = fields

  return (
    <div className="simulation">
      <div className="simulation__formWrapper">
        <HousingAlert
          type="error"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Text
          as="h1"
          size="lg"
          className="simulation__title"
          data-aos="fade-left"
          data-aos-delay="200"
          data-aos-duration="800"
        >
          simulador de crédito imobiliário
        </Text>
        <Text
          as="p"
          size="md"
          className="simulation__subtitle"
          data-aos="fade-left"
          data-aos-delay="300"
          data-aos-duration="800"
        >
          Preencha os campos abaixo para simular e conhecer as condições.
        </Text>
        <Form
          onSubmit={onSubmitCallback}
          form={form}
          data-aos="fade-left"
          data-aos-delay="400"
          data-aos-duration="800"
        >
          <Field
            field={propertyType}
            data-testid={propertyType.name}
            form={form}
            onChange={(e) => handleType(e.value)}
            disabled={isLoading}
          />
          <Field
            autoComplete="off"
            field={propertyValue}
            rules={{ min: 0, max: 999_999_999.99 }}
            data-testid={propertyValue.name}
            form={form}
            disabled={isLoading}
          />
          <Field
            autoComplete="off"
            field={inputValue}
            rules={{ min: 0, max: 999_999_999.99 }}
            data-testid={inputValue.name}
            form={form}
            disabled={isLoading}
          />
          <AttentionBox
            icon="sorriso"
            text="Você pode utilizar recursos próprios, FGTS e outras rendas"
          />
          <Field
            field={birthdate}
            data-testid={birthdate.name}
            form={form}
            onBlur={handleBirthDate}
            disabled={isLoading}
          />
          <Field
            field={financingTerm}
            data-testid={financingTerm.name}
            form={form}
            maskSuffix="anos"
            max={maxFinancingTerm}
            min={1}
            disabled={isLoading}
            resetField={form.resetField}
          />
          <p className="simulation__caption">{`de 1 a ${maxFinancingTerm} anos`}</p>

          <AttentionBox
            icon="notificacao"
            title="não se preocupe!"
            text="Você vai poder antecipar o valor das parcelas a qualquer momento."
          />

          <Field
            field={hasProperty}
            data-testid={hasProperty.name}
            form={form}
            disabled={isLoading}
          />

          <Field
            field={timeAcquireProperty}
            data-testid={timeAcquireProperty.name}
            form={form}
            required={false}
            disabled={isLoading}
          />

          <div className="simulation__insuranceTitleContainer">
            <Text as="p" className="simulation__insuranceContainerText">
              Escolha uma seguradora para contratar o Seguro Habitacional
              Obrigatório
            </Text>
            <Button
              variant="action"
              className="simulation__insuranceContainerButton"
              type="button"
              onClick={() => toggleInsuranceModal(true)}
            >
              entenda por que é obrigatório e conheça as condições gerais dos
              seguros
            </Button>
            <Field
              field={insurer}
              form={form}
              required
              className="simulation__insurerField"
              isDisabled={isLoading}
            />
          </div>
          <Sender
            onPrev={handlePrevStep}
            isNextDisabled={!isValid}
            className="simulation__sender"
            nextLabel="simular"
            data_testid="submit"
            isLoading={isLoading}
          />
        </Form>
      </div>

      <Modal
        type={insuranceModalType}
        show={insuranceModalShow}
        onCloseClick={() => toggleInsuranceModal(false)}
      >
        <AboutInsurance handleClick={() => toggleInsuranceModal(false)} />
      </Modal>
    </div>
  )
}

export default Simulation
