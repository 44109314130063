import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = {
    label: "IT:NL:NCC:CGI:Simulador:Simulador:SeguirPorWebOuApp",
    hashedCpf
  }

  const segment = {
    event: "page viewed",
    data: {
      step: "redirect app",
      cpf: hashedCpf
    }
  }

  trackPageview({ ga, segment })
}

const advanceToApp = (hashedCpf) => {
  const ga = {
    label: "BTN:SeguirPorWebOuApp:IrParaApp",
    category: "Clique",
    action: "Simulador",
    hashedCpf
  }

  const segment = {
    event: "ir para app clicked",
    data: {
      step: "redirect app",
      cpf: hashedCpf
    }
  }

  trackEvent({ ga, segment })
}

const advanceToBrowser = (hashedCpf) => {
  const ga = {
    label: "BTN:SeguirPorWebOuApp:ContinuarNoNavegador",
    category: "Clique",
    action: "Simulador",
    hashedCpf
  }

  const segment = {
    event: "continuar no navegador",
    data: {
      step: "redirect app",
      cpf: hashedCpf
    }
  }

  trackEvent({ ga, segment })
}

export { pageview, advanceToApp, advanceToBrowser }
