import { Field, Form, HousingAlert, Sender } from "@garantidos/components"

import AriaLabel from "@mobi/components/AriaLabel"
import Text from "@mobi/ds/components/Text"

import linksEnum from "pages/Proposal/constants/linksEnum"

import useSoftlead from "./hooks"

import "./style.scss"

const Softlead = () => {
  const {
    onSubmitCallback,
    fields,
    form,
    errorMessage,
    setErrorMessage,
    isLoading
  } = useSoftlead()
  const { cpf, name, email, phone } = fields
  const { isValid } = form

  return (
    <div className="softlead">
      <div className="softlead__formWrapper">
        <HousingAlert
          type="error"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Text
          as="h1"
          size="lg"
          className="softlead__title"
          aria-label="vamos começar nos conhecendo emoticon carinha piscando"
        >
          vamos começar nos conhecendo ;)
        </Text>
        <Form onSubmit={onSubmitCallback} form={form}>
          <Field
            autoComplete="off"
            field={cpf}
            form={form}
            data-testid={cpf.name}
            disabled={isLoading}
          />
          <Field
            autoComplete="off"
            field={name}
            form={form}
            data-testid={name.name}
            disabled={isLoading}
          />
          <Field
            field={email}
            form={form}
            data-testid={email.name}
            disabled={isLoading}
          />
          <Field
            autoComplete="off"
            field={phone}
            form={form}
            data-testid={phone.name}
            disabled={isLoading}
            aria-label={phone.ariaLabel}
            aria-labelledby=""
          />
          <AriaLabel
            ariaText="Ao continuar, autorizo os gestores de banco de dados do cadastro
              positivo disponibilizarem ao Itaú Unibanco S.A o meu histórico de
              crédito. Estou ciente dos termos de autorização e que poderei efetuar o cancelamento a qualquer momento."
          >
            <Text as="p" className="softlead__text">
              <span aria-hidden>
                Ao continuar, autorizo os gestores de banco de dados do cadastro
                positivo disponibilizarem ao Itaú Unibanco S.A o meu histórico
                de crédito. Estou ciente dos&nbsp;
              </span>
              <a
                className="softlead__link"
                target="_blank"
                rel="noreferrer noopener"
                href={linksEnum.AUTHORIZATION_TERM}
              >
                termos de autorização
              </a>
              <span aria-hidden>&nbsp;e que poderei efetuar o&nbsp;</span>
              <a
                target="_blank"
                rel="noreferrer noopener"
                className="softlead__link"
                href={linksEnum.POSITIVE_RECORD}
              >
                cancelamento
              </a>
              <span aria-hidden>&nbsp; a qualquer momento.</span>
            </Text>
          </AriaLabel>
          <AriaLabel ariaText="Autorizo também a consulta ao sistema de informações de crédito (SCR) do Banco Central, estou ciente que meus dados serão registrados no SCR e de acordo com a política de privacidade.">
            <Text as="p" className="softlead__text">
              <span aria-hidden>Autorizo também a consulta ao&nbsp;</span>
              <a
                target="_blank"
                rel="noreferrer noopener"
                className="softlead__link"
                href={linksEnum.CREDIT_INFORMATION_SYSTEM}
              >
                sistema de informações de crédito
              </a>
              <span aria-hidden>
                &nbsp;(SCR) do Banco Central, estou ciente que meus dados serão
                registrados no SCR e de acordo com a&nbsp;
              </span>
              <a
                className="softlead__link"
                target="_blank"
                rel="noreferrer noopener"
                href={linksEnum.TERMS_OF_USE}
              >
                política de privacidade.
              </a>
            </Text>
          </AriaLabel>
          <Sender
            showPrev={false}
            isNextDisabled={!isValid}
            className="softlead__sender"
            nextLabel="continuar"
            data_testid="submit"
            isLoading={isLoading}
          />
        </Form>
      </div>
    </div>
  )
}

export default Softlead
