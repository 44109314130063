import { useProposalContext } from "contexts/proposal"

import { createFinancingConditionsPayload, createLeadPayload } from "./handlers"

const createPayloadHandler = {
  lead: createLeadPayload,
  financingConditions: createFinancingConditionsPayload
}

const usePayloadHelper = () => {
  const {
    proposalData,
    stepInfo: { currentStep }
  } = useProposalContext()

  const createPayload = (formData) => {
    const handler = createPayloadHandler[currentStep]
    if (!handler) return formData

    return handler({ formData, proposalData })
  }

  return {
    createPayload
  }
}

export default usePayloadHelper
