import { useProposalContext } from "contexts/proposal"

import * as trackers from "../../../../trackings"

const useCardAccountNotHolder = () => {
  const {
    cpfData,
    proposalData: { proposal_integration_number }
  } = useProposalContext()

  const { hashedCpf } = cpfData

  const handleTrackEvent = () => {
    trackers.accountNotHolder(hashedCpf, proposal_integration_number)
  }

  return {
    handleTrackEvent
  }
}

export default useCardAccountNotHolder
