const infosBox = [
  {
    icon: "antecipacao_de_recebimento",
    title: "No Itaú tem o Pula Parcela",
    subText:
      "Pule até 2 parcelas seguidas do seu empréstimo por ano, podendo ser recontratado a cada 12 meses.",
    _uid: "133771177"
  },
  {
    icon: "calendario",
    title: "Prazo maior para pagar",
    subText: "Até 420 meses (35 anos) para pagar e pode quitar quando quiser.",
    _uid: "19752426"
  }
]

export default infosBox
