const data = [
  {
    boldText: "Capitais e regiões metropolitanas",
    text: "4004 7051"
  },
  {
    boldText: "Demais localidades",
    text: "0300 789 7051 (De segundas à sexta, das 08h às 22h)."
  }
]

export default data
