import { Fragment } from "react"

import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import "./style.scss"

import aboutAmortizationData from "./data"

const AboutAmortization = ({ onClickCallback }) => (
  <div className="aboutAmortization">
    {aboutAmortizationData.map(({ title, content }, index) => (
      <Fragment key={index}>
        <If
          condition={title}
          renderIf={
            <Text as="h2" className="aboutAmortization__title">
              {title}
            </Text>
          }
        />
        <div>
          {content.map((text, textIndex) => (
            <Text key={textIndex} as="p" className="aboutAmortization__text">
              {text}
            </Text>
          ))}
        </div>
      </Fragment>
    ))}
    <Button
      fluid
      onClick={onClickCallback}
      className="aboutAmortization__button"
    >
      Continuar
    </Button>
  </div>
)

export default AboutAmortization
