import { fields as leadFields } from "pages/Proposal/steps/Lead/fields"

import { fields as leadPartnerFieldsAddress } from "pages/Proposal/steps/Lead/components/FormGroupPartnerAddress/fields"
import { fields as leadPartnerFieldsInfo } from "pages/Proposal/steps/Lead/components/FormGroupPartnerInfo/fields"

const createLeadPayload = ({ formData, proposalData }) => {
  const leadProps = Object.values(leadFields).map((field) => field.name)
  const payload = {}
  leadProps.forEach((prop) => {
    payload[prop] = formData[prop] || proposalData[prop]
  })

  const maritalStatusProp = leadFields.maritalStatus.name
  const stableUnionProp = leadFields.stableUnion.name
  const shareIncomeProp = leadPartnerFieldsInfo.shareIncome.name
  const sameAddressProp = leadPartnerFieldsInfo.sameAddress.name

  if (formData[shareIncomeProp]) {
    const partnerPropsInfo = Object.values(leadPartnerFieldsInfo).map(
      (field) => field.name
    )
    partnerPropsInfo.forEach((prop) => {
      payload[prop] = formData[prop] || proposalData[prop]
    })

    if (!formData[sameAddressProp]) {
      const partnerPropsAddress = Object.values(leadPartnerFieldsAddress).map(
        (field) => field.name
      )
      partnerPropsAddress.forEach((prop) => {
        payload[prop] = formData[prop] || proposalData[prop]
      })
    }
  }

  if (
    !formData[shareIncomeProp] &&
    (formData[maritalStatusProp] === "CASADO" || formData[stableUnionProp])
  ) {
    const partnerPropsInfo = Object.values(leadPartnerFieldsInfo)
      .map((field) => field.name)
      .filter((field) =>
        [
          "share_income",
          "partner_name",
          "partner_cpf",
          "partner_birthdate",
          "partner_nationality"
        ].includes(field)
      )
    partnerPropsInfo.forEach((prop) => {
      payload[prop] = formData[prop] || proposalData[prop]
    })
  }

  return payload
}

export default createLeadPayload
