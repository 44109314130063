import { proposalHttpController } from "../../services"

const simulationAssetContextApi = ({ setIsLoading }) => {
  const fetchAsset = async ({ hasLoading = true, payload, recaptcha }) => {
    setIsLoading(hasLoading)
    try {
      const response = await proposalHttpController({
        method: "calculateAssetValue",
        payload: payload,
        recaptcha,
      })

      return response
    } finally {
      setIsLoading(false)
    }
  }

  const fetchDeadlines = async ({ hasLoading = true, payload, recaptcha }) => {
    setIsLoading(hasLoading)
    try {
      const response = await proposalHttpController({
        method: "calculateDeadlines",
        payload,
        recaptcha,
      })

      return response
    } finally {
      setIsLoading(false)
    }
  }

  return { fetchAsset, fetchDeadlines }
}

export { simulationAssetContextApi }
