import { useEffect } from "react"

import { Sender } from "@garantidos/components"

import Text from "@mobi/ds/components/Text"

import "./styles.scss"

const ResultCardDetails = ({ data, isLoading, handleNextStep }) => {
  const { title, diferential, listItems, feeType } = data

  useEffect(() => {
    const [closeButtonElement] = document.querySelectorAll(
      ".resultDetails__modal button.ds-modal-container__close-button"
    )
    closeButtonElement && closeButtonElement.focus()
  }, [])

  return (
    <div className="resultCardDetails">
      <Text as="h3" className="resultCardDetails__title">
        detalhes da oferta
      </Text>
      <Text as="p" className="resultCardDetails__description">
        <strong className="resultCardDetails__description--highlight">
          {title}
        </strong>
        {diferential}
      </Text>
      <Text as="h4" className="resultCardDetails__costsTitle">
        valores totais e custos
      </Text>
      <ul
        className="resultCardDetails__valuesList"
        aria-label="Lista com principais valores da opção de financiamento"
      >
        {listItems?.map(({ label, value }) => (
          <li key={label} className="resultCardDetails__valuesListItem">
            <Text as="p" className="resultCardDetails__label">
              {label}
            </Text>
            <Text as="p" className="resultCardDetails__value">
              {value}
            </Text>
          </li>
        ))}
      </ul>
      <Sender
        onNext={() => handleNextStep(feeType)}
        nextLabel="seguir com estas condições"
        showPrev={false}
        className="resultCardDetails__sender"
        isLoading={isLoading}
      />
    </div>
  )
}

export default ResultCardDetails
