import { Fragment } from "react"

import { Field, Form, HousingAlert } from "@garantidos/components"

import Button from "@mobi/ds/components/Button"
import Modal from "@mobi/ds/components/Modal"
import Text from "@mobi/ds/components/Text"

import AboutInsurance from "../../../Simulation/components/AboutInsurance"
import AttentionBox from "../../../Simulation/components/AttentionBox/index"

import useSimulationEdit from "./hooks"

import "./style.scss"

const SimulationEdit = ({ onSubmitEditForm, updateFieldsToShow }) => {
  const {
    fields,
    form,
    onSubmitCallback,
    toggleInsuranceModal,
    insuranceModalType,
    insuranceModalShow,
    errorMessage,
    setErrorMessage,
    maxFinancingTerm
  } = useSimulationEdit({ onSubmitEditForm, updateFieldsToShow })
  const { isValid } = form
  return (
    <div className="simulationEdit">
      <Text as="h3" className="simulationEdit__title">
        editar simulador de crédito imobiliário
      </Text>
      <HousingAlert
        type="error"
        message={errorMessage}
        setMessage={setErrorMessage}
      />
      <Form
        onSubmit={onSubmitCallback}
        form={form}
        className="simulationEdit__form"
      >
        {Object.keys(fields).map((property, index) => (
          <Fragment key={index}>
            {fields[property].type === "customRadio" && (
              <>
                <AttentionBox
                  icon="notificacao"
                  text="você vai poder antecipar o valor das parcelas a qualquer momento."
                />
                <div className="simulationEdit__insuranceContainer">
                  <Text
                    as="p"
                    className="simulationEdit__insuranceContainerLabel"
                  >
                    escolha uma seguradora
                  </Text>
                  <Button
                    variant="action"
                    className="simulationEdit__insuranceContainerButton"
                    type="button"
                    onClick={() => toggleInsuranceModal(true)}
                  >
                    saiba mais
                  </Button>
                </div>
              </>
            )}
            <div className="simulationEdit__inputGroup">
              <Field
                field={fields[property]}
                form={form}
                {...(fields[property].type === "currency"
                  ? { rules: { min: 0, max: 999_999_999.99 } }
                  : {})}
                {...(fields[property].name === "financing_term"
                  ? {
                      maskSuffix: "anos",
                      max: maxFinancingTerm,
                      min: 1
                    }
                  : {})}
                {...(fields[property].name === "time_acquire_property"
                  ? { required: false }
                  : {})}
              />
              {fields[property].name === "financing_term" && (
                <p className="simulation__caption">{`de 1 a ${maxFinancingTerm} anos`}</p>
              )}
            </div>
          </Fragment>
        ))}

        <Button
          fluid
          type="submit"
          disabled={!isValid}
          className="simulationEdit__button"
        >
          salvar alterações
        </Button>
      </Form>
      <Modal
        type={insuranceModalType}
        show={insuranceModalShow}
        onCloseClick={() => toggleInsuranceModal(false)}
      >
        <AboutInsurance handleClick={() => toggleInsuranceModal(false)} />
      </Modal>
    </div>
  )
}

export default SimulationEdit
