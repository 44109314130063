import { HousingAlert } from "@garantidos/components"

import Modal from "@mobi/ds/components/Modal"

import DetailsList from "./components/DetailsList"
import ExtraInfos from "./components/ExtraInfos"
import Result from "./components/Result"
import SimulationEdit from "./components/SimulationEdit"
import SimulationResultHeader from "./components/SimulationResultHeader"

import useSimulationResult from "./hooks"

import "./style.scss"

const SimulationResult = () => {
  const {
    modalShow,
    handleNextStep,
    toggleModal,
    modalType,
    errorMessage,
    setErrorMessage,
    fieldsToShow,
    updateFieldsToShow
  } = useSimulationResult()

  return (
    <>
      <div className="innerContainer simulationResult">
        <div aria-hidden="true" className="simulationResult__bgicon" />
        <div className="simulationResult__infoContainer">
          <HousingAlert
            type="error"
            message={errorMessage}
            setMessage={setErrorMessage}
          />
          <SimulationResultHeader handleClick={() => toggleModal(true)} />
          <DetailsList fieldsToShow={fieldsToShow} />
          <Result handleNextStep={handleNextStep} />
        </div>
        <div className="simulationResult__senderContainer">
          <ExtraInfos />
        </div>
      </div>
      <Modal
        type={modalType}
        show={modalShow}
        onCloseClick={() => toggleModal(false)}
      >
        <SimulationEdit
          onSubmitEditForm={() => toggleModal(false)}
          updateFieldsToShow={updateFieldsToShow}
        />
      </Modal>
    </>
  )
}

export default SimulationResult
