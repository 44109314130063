export const fields = {
  hasReferral: {
    type: "radio",
    name: "has_referral",
    options: [
      {
        id: "has_referral_nao",
        value: "nao",
        label: "não",
        checked: true
      },
      {
        id: "has_referral_sim",
        value: "sim",
        label: "sim"
      }
    ],
    sendFormat: "bool",
    fetchFormat: "bool"
  },
  referralCode: {
    type: "text",
    name: "referral_code",
    label: "código itaú"
  },
  referralPhone: {
    type: "cellphone",
    name: "referral_phone",
    label: "telefone do parceiro",
    sendFormat: "phone",
    fetchFormat: "phone"
  }
}
