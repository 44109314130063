import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import useFinishedComplementaryText from "./hooks"

import "./style.scss"

const FinishedComplementaryText = ({ isAccountHolder = false }) => {
  const { handleTrackEvent, buttonInfo, text } =
    useFinishedComplementaryText(isAccountHolder)

  const { href, label } = buttonInfo
  return (
    <If
      condition={!isAccountHolder}
      renderIf={
        <div
          className="finishedComplementary"
          data-aos="fade"
          data-aos-delay="200"
          data-aos-duration="800"
        >
          <Text as="p" className="finishedComplementary__text">
            {text}
          </Text>
          <If
            condition={!!buttonInfo}
            renderIf={
              <Button
                className="finishedComplementary__button"
                tagName="a"
                href={href}
                target="_blank"
                rel="noreferrer"
                fluid
                onClick={handleTrackEvent}
              >
                {label}
              </Button>
            }
          />
        </div>
      }
    />
  )
}

export default FinishedComplementaryText
