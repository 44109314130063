import { Icon } from "@garantidos/components"

import AriaLabel from "@mobi/components/AriaLabel"
import Text from "@mobi/ds/components/Text"

import "./style.scss"

import data from "./data"

const CardAccountHolder = () => (
  <div className="cardAccountHolder">
    <Text as="p" className="cardAccountHolder__text">
      Você também pode consultar o andamento da sua proposta através do Itaú na
      internet ou pelo app nos caminhos:
    </Text>
    {data.map((card) => (
      <div className="cardAccountHolder__infoContainer">
        <div className="cardAccountHolder__iconContainer">
          <Icon className="cardAccountHolder__icon" icon={card.icon} />
        </div>
        <AriaLabel ariaText={card.subText}>
          <Text as="p" className="cardAccountHolder__text" aria-hidden="true">
            {card.title}
          </Text>
        </AriaLabel>
      </div>
    ))}
  </div>
)

export default CardAccountHolder
