import { Fragment } from "react"

import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import "./style.scss"

import aboutCreditLinesData from "./data"

const AboutCreditLines = ({ onClickCallback }) => (
  <div className="aboutCreditLines">
    {aboutCreditLinesData.map(({ title, content }, index) => (
      <Fragment key={index}>
        <Text as="h2" className="aboutCreditLines__title">
          {title}
        </Text>
        <div>
          {content.map((text, textIndex) => (
            <Text key={textIndex} as="p" className="aboutCreditLines__text">
              {text}
            </Text>
          ))}
        </div>
      </Fragment>
    ))}
    <Button
      fluid
      onClick={onClickCallback}
      className="aboutCreditLines__button"
    >
      Continuar
    </Button>
  </div>
)

export default AboutCreditLines
