import { useStep } from "@mobi/libraries/step"

import errorsEnum from "pages/Proposal/constants/errorsEnum"

import { useProposalContext } from "contexts/proposal"

const { POTENTIAL_CPF, CPF_NO_CREDIT, CPF_NO_GROUP } = errorsEnum

const ALTERNATIVE_FLOW_CODES = [CPF_NO_CREDIT, POTENTIAL_CPF, CPF_NO_GROUP]

const useRejectionRedirect = () => {
  const { updateProposalData } = useProposalContext()
  const { next } = useStep()

  const redirectToRejectionPage = (errorCode) => {
    updateProposalData({
      payload: { errorCode },
      clear: true
    })
    if (ALTERNATIVE_FLOW_CODES.includes(errorCode)) {
      next("alternativeFlow")
    } else {
      next("rejection")
    }
  }

  return {
    redirectToRejectionPage
  }
}

export default useRejectionRedirect
