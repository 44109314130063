import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = { label: "IT:NL:NCC:CredImob:Simulador:SuaSimulacao", hashedCpf }
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/simulacao/resultado-simulacao",
    implementationTeam: "imob:v01"
  }
  const segment = "Simulation Completed"

  trackPageview({ ga, ga4, segment })
}

const backHome = (hashedCpf) => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/simulacao/resultado-simulacao",
    hashedCpf,
    implementationTeam: "imob:v01",
    detail: "btn:voltar-inicio",
    eventName: "click"
  }
  trackEvent({ ga4 })
}

const editGA4 = () => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/simulacao/resultado-simulacao",
    implementationTeam: "imob:v01",
    detail: "btn:editar",
    eventName: "click"
  }

  trackEvent({ ga4 })
}

const advanceCredit = () => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/simulacao/resultado-simulacao",
    implementationTeam: "imob:v01",
    detail: "btn:credito-imobiliario-normal",
    eventName: "click"
  }
  const segment = {
    event: "button_credimob_follow"
  }

  trackEvent({ ga4, segment })
}

const seeDetailsCredit = () => {
  const segment = {
    event: "button_credimob_details"
  }

  trackEvent({ segment })
}

const advanceCreditPoupanca = () => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/simulacao/resultado-simulacao",
    implementationTeam: "imob:v01",
    detail: "btn:credito-imobiliario-poupanca",
    eventName: "click"
  }
  const segment = {
    event: "button_credimobfees_follow"
  }

  trackEvent({ ga4, segment })
}

const seeDetailsCreditPoupanca = () => {
  const segment = {
    event: "button_credimobfees_details"
  }

  trackEvent({ segment })
}

const simulationGA4 = (hashedCpf) => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/simulacao/resultado-simulacao",
    implementationTeam: "imob:v01",
    eventName: "simulation",
    hashedCpf
  }

  trackEvent({ ga4 })
}

const advanceGA = (hashedCpf) => {
  const ga = {
    label: "BTN:Simulador:BotaoSolicitarAnaliseCredito",
    action: "Simulador",
    hashedCpf
  }
  const segment = "Proposal Started"

  trackEvent({
    ga,
    segment
  })
}

const alert = (hashedCpf, error) => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/simulacao/resultado-simulacao",
    hashedCpf,
    implementationTeam: "imob:v01",
    eventName: "alert",
    description: "erro",
    detail: error.message
      ?.toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replaceAll(/ /g, "-")
      .replaceAll(/!/g, "")
  }

  trackEvent({ ga4 })
}

export {
  pageview,
  backHome,
  advanceCreditPoupanca,
  seeDetailsCreditPoupanca,
  advanceCredit,
  seeDetailsCredit,
  simulationGA4,
  advanceGA,
  editGA4,
  alert
}
