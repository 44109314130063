import { renewTime, setCpfInStorage, setItem } from "@garantidos/utils"

import {
  apiStepInfo,
  getFallbackStepInfo,
  getStepInfo,
} from "../data/proposal/entities/stepInfo"
import { proposalHttpController } from "../services"
import { hashDocument } from "../services/httpRequests"
import { financingConditionsCgiContextApi } from "./cgi/financingConditions"
import { simulationAssetContextApi } from "./consorcio/simulationAsset"
import { financingConditionsImobiContextApi } from "./imobi/financingConditions"
import { smsContextApi } from "./sms"

const DEFAULT_ERROR_MESSAGE =
  "Encontramos um erro, por favor tente novamente mais tarde"

const proposalContextApi = ({
  stepInfo,
  setProposalData,
  setStepInfo,
  setIsLoading,
  stepsData,
  setErrorMessage,
  setErrorFields,
  cpfData,
  setCpfData,
}) => {
  const { fetchAsset, fetchDeadlines } = simulationAssetContextApi({
    setIsLoading,
  })
  const { checkFinanceIOF } = financingConditionsCgiContextApi({ setIsLoading })
  const { checkFinanceExpense } = financingConditionsImobiContextApi({
    setIsLoading,
  })
  const { resendToken } = smsContextApi({ setIsLoading })

  const updateProposalData = async ({ payload = {}, clear = false }) => {
    if (clear) {
      setProposalData(payload)
    } else {
      setProposalData((prevData) => ({
        ...prevData,
        ...payload,
      }))
    }
  }

  const updateStepInfo = (data) => {
    const newStepInfo = getStepInfo(data, stepsData)

    const fallBackStepInfo = getFallbackStepInfo(newStepInfo, stepsData)
    const sanitizedStepInfo = {
      currentFlow: newStepInfo.currentFlow || fallBackStepInfo.currentFlow,
      currentStep: newStepInfo.currentStep || fallBackStepInfo.currentStep,
      previousStep: newStepInfo.previousStep || fallBackStepInfo.previousStep,
      nextStep: newStepInfo.nextStep || fallBackStepInfo.nextStep,
    }

    setStepInfo(sanitizedStepInfo)
  }

  const updateCpfData = ({ cpf = "", hashedCpf = "" }) => {
    setCpfData({ cpf, hashedCpf })
  }

  // Similar ao Summary em veículos
  // Usado apenas para o previousStep
  const fetchProposalData = async ({
    hasLoading = true,
    flow = "",
    step = "",
    recaptcha = false,
  }) => {
    setIsLoading(hasLoading)
    clearErrors()
    await checkHashedCpf()
    const { steps } = stepsData
    const apiStep = step ? steps[step].name : null

    const apiInfoStep = apiStepInfo(stepInfo, stepsData)

    flow = flow || apiInfoStep.flow
    const currentStep = apiStep || apiInfoStep.current_step

    try {
      const response = await proposalHttpController({
        method: "get",
        flow,
        step: currentStep,
        recaptcha,
      })

      updateProposalData({ payload: response.step_data, clear: true })
      updateStepInfo(response.step_navigation)
      return response.step_data
    } finally {
      setIsLoading(false)
    }
  }

  //Similar ao Form em veículos
  const sendProposalData = async ({
    method = "post",
    payload,
    step = "",
    flow = "",
    hasLoading = true,
    clearStepNaviagation = true,
  }) => {
    setIsLoading(hasLoading)
    clearErrors()
    const cpf =
      payload.cpf ?? payload.proponent_document ?? payload.document_number

    await checkHashedCpf(cpf)

    const { flow: currentFlow, current_step } = apiStepInfo(stepInfo, stepsData)

    flow = flow ? flow : currentFlow

    try {
      const response = await proposalHttpController({
        payload,
        method,
        flow,
        step: step || current_step,
      })

      updateProposalData({ payload: response.step_data, clear: true })
      updateStepInfo(response.step_navigation)

      const fullStepResponse = {
        stepData: response.step_data,
        stepNavigation: getStepInfo(response.step_navigation, stepsData),
      }
      return clearStepNaviagation ? response.step_data : fullStepResponse
    } finally {
      setIsLoading(false)
    }
  }

  const retrieveProposal = async ({ route, recaptcha }) => {
    const responseToken = await proposalHttpController({
      method: "retrieveProposal",
      route,
      recaptcha,
    })
    const { step_data, step_navigation } = responseToken

    await updateStepInfo(step_navigation)
    await updateProposalData({ payload: step_data, clear: true })
  }

  const retrieveProposalRemarketing = async ({
    route,
    recaptcha,
    remarketingId,
  }) => {
    const response = await proposalHttpController({
      method: "retrieveProposalRemarketing",
      route,
      recaptcha,
      payload: { remarketingId },
    })

    if (!response || typeof response !== "object") return

    const { step_data, step_navigation, refresh_token } = response

    if (step_data) await updateProposalData({ payload: step_data, clear: true })
    if (step_navigation) await updateStepInfo(step_navigation)
    if (refresh_token) {
      const { token, valid_time, server_time } = refresh_token
      const expires = renewTime(valid_time, server_time)
      const authData = { token, expires }
      setItem("appToken", JSON.stringify(authData))
    }
  }

  const errorHandler = (error) => {
    if (error === null || error === undefined) {
      error = { message: DEFAULT_ERROR_MESSAGE }
    }
    setErrorMessage(error.message)
    if (error.form_validation_error?.fields) {
      setErrorFields(error.form_validation_error.fields)
    }
  }

  const clearErrors = (errorMessage = true, errorFields = true) => {
    errorMessage && setErrorMessage("")
    errorFields && setErrorFields({})
  }

  const checkHashedCpf = async (newCpf = null) => {
    const { cpf, hashedCpf } = cpfData

    if (newCpf === cpf && !!hashedCpf) return
    if (!newCpf && !!cpf && !!hashedCpf) return

    const newCpfData = { cpf: newCpf ?? cpf ?? "", hashedCpf: hashedCpf ?? "" }

    if (newCpf !== cpf || !hashedCpf) {
      const newHashedCpf = await hashDocument(newCpfData.cpf)
      newCpfData.hashedCpf = newHashedCpf
    }

    setCpfData(newCpfData)
    setCpfInStorage(newCpfData)
  }

  return {
    fetchProposalData,
    sendProposalData,
    checkFinanceExpense,
    checkFinanceIOF,
    resendToken,
    fetchDeadlines,
    fetchAsset,
    updateProposalData,
    updateStepInfo,
    errorHandler,
    checkHashedCpf,
    updateCpfData,
    retrieveProposal,
    retrieveProposalRemarketing,
  }
}

export { proposalContextApi }
