import { fields as FormGroupAccountHolderFields } from "./components/FormGroupAccountHolder/fields"
import { fields as formGroupCreditLineFields } from "./components/FormGroupCreditLine/fields"
import { fields as formGroupEasinesFields } from "./components/FormGroupEasiness/fields"
import { fields as formGroupReferralFields } from "./components/FormGroupReferral/fields"

export const fields = {
  ...formGroupCreditLineFields,
  ...formGroupEasinesFields,
  ...formGroupReferralFields,
  ...FormGroupAccountHolderFields
}
