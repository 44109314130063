import { proposalHttpController } from "../../services"

const financingConditionsCgiContextApi = ({ setIsLoading }) => {
  const checkFinanceIOF = async ({ hasLoading = true }) => {
    setIsLoading(hasLoading)
    try {
      const response = await proposalHttpController({
        method: "financeIof",
      })
      return response.message || ""
    } finally {
      setIsLoading(false)
    }
  }

  return { checkFinanceIOF }
}

export { financingConditionsCgiContextApi }
