import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"
import IconAplicativos from "@mobi/ds/static/icons/outlined/aplicativos.svg"
import IconComputador from "@mobi/ds/static/icons/outlined/computador.svg"
import IconCreditoImobiliario from "@mobi/ds/static/icons/outlined/credito-imobiliario.svg"
import IconDocumento from "@mobi/ds/static/icons/outlined/documento.svg"

import { IdsIcon, IdsLoading, IdsMainButton } from "@ids/react"
import MulherComemorandoBemSvg from "images/mulher_comemorando_bem.svg"

import useRedirectApp from "./hooks"

import "./style.scss"

const RedirectApp = () => {
  const {
    handleRedirectApp,
    handleKeepBrowser,
    isLoading,
    qrCodeBase64,
    hasMobileView
  } = useRedirectApp()

  return (
    <div className="redirectApp">
      <div className="redirectApp__wrapper">
        <div className="redirectApp__heading">
          <If
            condition={hasMobileView}
            renderIf={
              <IconAplicativos
                width={32}
                height={32}
                className="redirectApp__icon"
                aria-hidden
              />
            }
          />
          <h1 className="redirectApp__title">
            Que tal continuar sua simulação pelo app do Itaú?
          </h1>

          <If
            condition={!hasMobileView}
            renderIf={
              <MulherComemorandoBemSvg className="redirectApp__illustration" />
            }
          />
        </div>

        <div className="redirectApp__content">
          <div className="redirectApp__card">
            <Text as="h2" className="redirectApp__subtitle">
              Veja as vantagens que você encontrará por lá:
            </Text>
            <ul className="redirectApp__contentCard">
              <li className="redirectApp__contentItem">
                <IconCreditoImobiliario
                  width={24}
                  height={24}
                  className="redirectApp__icon"
                  aria-hidden
                />
                <Text
                  as="p"
                  size="md"
                  weight="400"
                  className="redirectApp__text"
                >
                  Consulta de crédito pré aprovado
                </Text>
              </li>

              <li className="redirectApp__contentItem">
                <IconComputador
                  width={24}
                  height={24}
                  className="redirectApp__icon"
                  aria-hidden
                />
                <Text
                  as="p"
                  size="md"
                  weight="400"
                  className="redirectApp__text"
                >
                  Preenchimento simplificado dos campos
                </Text>
              </li>

              <li className="redirectApp__contentItem">
                <IconDocumento
                  width={24}
                  height={24}
                  className="redirectApp__icon"
                  aria-hidden
                />
                <Text
                  as="p"
                  size="md"
                  weight="400"
                  className="redirectApp__text"
                >
                  Acompanhamento da análise de crédito de forma fácil e rápida
                </Text>
              </li>
            </ul>

            <If
              condition={!hasMobileView}
              renderIf={
                <div className="redirectApp__qrcodeWrapper">
                  {qrCodeBase64 && (
                    <img
                      src={qrCodeBase64}
                      className="redirectApp__qrcode"
                      alt="Imagem do QR Code que redireciona para o app do Itaú"
                    />
                  )}

                  <div className="redirectApp__nextSteps">
                    <h2 className="redirectApp__nextStepsTitle">
                      Próximos passos
                    </h2>

                    <ul className="redirectApp__nextStepsItems">
                      <li className="redirectApp__nextStepsItem">
                        <IdsIcon size="M" variant="neutral">
                          celular_pre_pago_outline
                        </IdsIcon>

                        <p className="redirectApp__nextStepsText">
                          Aponte a câmera do seu celular para o QR ao lado
                        </p>
                      </li>

                      <li className="redirectApp__nextStepsItem">
                        <IdsIcon size="M" variant="neutral">
                          download_base
                        </IdsIcon>
                        <p className="redirectApp__nextStepsText">
                          Acesse o app e faça o seu login
                        </p>
                      </li>

                      <li className="redirectApp__nextStepsItem">
                        <IdsIcon size="M" variant="neutral">
                          cartao_outline
                        </IdsIcon>
                        <p className="redirectApp__nextStepsText">
                          Continue a sua simulação pelo App
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              }
            />
          </div>

          <div className="redirectApp__buttonContainer">
            <If
              condition={hasMobileView}
              renderIf={
                <IdsMainButton
                  onClick={handleRedirectApp}
                  full
                  aria-label="ir para o aplicativo"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <IdsLoading size="XS" contrast="onBrand" />
                  ) : (
                    "Ir para o app"
                  )}
                </IdsMainButton>
              }
            />
            <IdsMainButton
              variant="secondary"
              onClick={handleKeepBrowser}
              full
              className="redirectApp__keepBrowserButton"
              disabled={isLoading}
            >
              {isLoading ? (
                <IdsLoading size="XS" variant="secondary" />
              ) : (
                "Continuar no navegador"
              )}
            </IdsMainButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RedirectApp
