import { useEffect, useState } from "react"

import { trackEvent } from "@garantidos/utils/tracking"

import { useFlags } from "pages/Proposal/hooks/useFlags"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "../../trackings"
import { fields } from "./fields"

const useFormGroupPartnerInfo = () => {
  const { cpfData } = useProposalContext()

  const { hashedCpf } = cpfData

  const {
    isSameAddress,
    isPartnerRetired,
    isMarried,
    isStableUnion,
    isShareIncome
  } = useFlags()

  const [eventLabel, setEventLabel] = useState("")

  const trackingInfo = {
    event: {
      ga: {
        label: `BTN:Simulador:${eventLabel}`,
        action: "Simulador",
        hashedCpf
      }
    }
  }

  const {
    shareIncome,
    partnerName,
    partnerCpf,
    partnerBirthdate,
    partnerNationality,
    partnerMaritalStatus,
    partnerOccupationCategory,
    partnerMonthlyIncome,
    sameAddress
  } = fields

  useEffect(() => {
    if (eventLabel === "") return
    trackEvent(trackingInfo.event)
  }, [eventLabel])

  useEffect(() => {
    partnerMaritalStatus.options = partnerMaritalStatus.options.filter(
      (option) => option.value !== "CASADO"
    )
  }, [])

  const hangleShareIncomeChange = (value) => {
    trackers.shareIncome(value === "sim" ? true : false)
    value === "sim"
      ? setEventLabel("SomarRendaSim")
      : setEventLabel("SomarRendaNão")
  }

  const handleSameAddress = (value) => {
    trackers.sameAddress(value === "sim" ? true : false)
  }

  const basicInfoFields = {
    partnerName,
    partnerCpf,
    partnerBirthdate,
    partnerNationality
  }

  return {
    shareIncome,
    basicInfoFields,
    partnerMaritalStatus,
    partnerOccupationCategory,
    partnerMonthlyIncome,
    sameAddress,
    isSameAddress,
    isPartnerRetired,
    isMarried,
    isShareIncome,
    isStableUnion,
    hangleShareIncomeChange,
    handleSameAddress
  }
}

export default useFormGroupPartnerInfo
