import { UF_OPTIONS } from "../../../../constants/dropdownOptions"

export const fields = {
  partnerCep: {
    type: "cep",
    name: "partner_cep",
    label: "CEP",
    sendFormat: "cep"
  },
  partnerStreet: {
    type: "text",
    name: "partner_street",
    label: "endereço"
  },
  partnerNeighborhood: {
    type: "text",
    name: "partner_neighborhood",
    label: "bairro"
  },
  parnterState: {
    type: "select",
    name: "partner_state",
    label: "estado",
    placeholder: "selecione",
    options: UF_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select"
  },
  partnerCity: {
    type: "text",
    name: "partner_city",
    label: "cidade"
  }
}
