import Text from "@mobi/ds/components/Text"

import CardFooter from "./components/CardFooter"

import useAccompanyCard from "./hooks"

import "./style.scss"

const AccompanyCard = ({ isAccountHolder = false }) => {
  const { CardContent } = useAccompanyCard(isAccountHolder)
  return (
    <div className="accompanyCard">
      <Text as="h3" className="accompanyCard__title">
        acompanhe sua análise de crédito
      </Text>
      <CardContent />
      <CardFooter />
    </div>
  )
}

export default AccompanyCard
