import { useState } from "react"

import { useForm, usePayloadFormatters } from "@garantidos/hooks"
import { proposalHttpController } from "@garantidos/services"
import { trackEvent } from "@garantidos/utils/tracking"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

import validatorsDictionary from "pages/Proposal/hooks/validators"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { fields } from "./fields"

const useSimulationEdit = ({ onSubmitEditForm, updateFieldsToShow }) => {
  const [insuranceModalShow, setInsuranceModalShow] = useState(false)
  const {
    proposalData,
    updateProposalData,
    setIsLoading,
    errorMessage,
    setErrorMessage,
    errorHandler,
    isLoading,
    setIsLoaderComponentVisible
  } = useProposalContext()
  const { form } = useForm({
    fields,
    context: ProposalContext,
    validatorsDictionary
  })

  const { formatSendPayload } = usePayloadFormatters(fields)

  const isMobile = useMediaQuery("mobile")

  const trackingInfo = {
    event: {
      segment: "Simulation Edited"
    }
  }

  const handleQualifiedTracking = (payload) => {
    trackEvent({
      ga: {
        label: `BTN:Simulador:Simulador:PossuiImovel:${payload.has_property}`
      }
    })

    if (payload.time_acquire_property) {
      trackEvent({
        ga: {
          label: `BTN:Simulador:Simulador:PensaAdquirirImovel:${payload.time_acquire_property.replaceAll(
            " ",
            ""
          )}`
        }
      })
    }
  }

  const onSubmitCallback = async (formData) => {
    if (isLoading) return
    try {
      onSubmitEditForm()
      setIsLoading(true)
      setIsLoaderComponentVisible(true)
      const payload = formatSendPayload(formData)

      await proposalHttpController({
        payload,
        method: "post",
        flow: "default",
        step: "softlead-simulation"
      })

      const response = await proposalHttpController({
        payload,
        method: "post",
        flow: "default",
        step: "simulation"
      })

      updateProposalData({ payload: response.step_data })
      updateFieldsToShow()

      handleQualifiedTracking(payload)
      trackEvent(trackingInfo.event)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
      setIsLoaderComponentVisible(false)
    }
  }

  const toggleInsuranceModal = (state) => {
    setInsuranceModalShow(state)
  }

  return {
    onSubmitCallback,
    fields,
    insuranceModalShow,
    insuranceModalType: isMobile ? "center" : "pop",
    toggleInsuranceModal,
    form,
    errorMessage,
    setErrorMessage,
    maxFinancingTerm: proposalData.property_type === "COMERCIAL" ? 20 : 35
  }
}

export default useSimulationEdit
