import { fields as accountFields } from "../../../steps/FinancingConditions/components/FormGroupAccountHolder/fields"
import { fields as creditLineFields } from "../../../steps/FinancingConditions/components/FormGroupCreditLine/fields"
import { fields as easinesFields } from "../../../steps/FinancingConditions/components/FormGroupEasiness/fields"
import { fields as referralFields } from "../../../steps/FinancingConditions/components/FormGroupReferral/fields"

const createLeadPayload = ({ formData }) => {
  const payload = {}

  // Flags para auxiliar a definir quais campos estarão no payload
  const flags = {
    hasReferral: formData[referralFields.hasReferral.name] || false
  }

  // Adiciona ao payload os campos referentes à linha de crédito
  const creditLineProps = Object.values(creditLineFields).map(
    (field) => field.name
  )
  creditLineProps.forEach((prop) => {
    payload[prop] = formData[prop]
  })
  // Adiciona ao payload os campos referentes a facilidades.

  const easinesProps = Object.values(easinesFields).map((field) => field.name)
  easinesProps.forEach((prop) => {
    payload[prop] = formData[prop] || false
  })

  // Adiciona ao payload a flag has_referral
  const referralProp = referralFields.hasReferral.name
  payload[referralProp] = formData[referralProp] || false

  // Adiciona informações sobre referral
  if (flags.hasReferral) {
    const referralProps = [
      referralFields.referralCode.name,
      referralFields.referralPhone.name
    ]
    referralProps.forEach((prop) => {
      payload[prop] = formData[prop]
    })
  }

  // Adiciona informação sobre possuir conta no banco
  const accountProp = accountFields.isAccountHolder.name
  payload[accountProp] = formData[accountProp] || false

  // Adiciona o submit_step ao payload
  payload["submit_step"] = true

  return payload
}

export default createLeadPayload
