import { trackEvent, trackPageview } from "@garantidos/utils/tracking"

const pageview = (hashedCpf) => {
  const ga = { label: "IT:NL:NCC:CredImob:Simulador:Financiamento", hashedCpf }
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/sobre-o-financiamento",
    implementationTeam: "imob:v01"
  }
  trackPageview({ ga, ga4 })
}

const aboutCreditLines = () => {}

const aboutAmortization = () => {}

const feeType = (type) => {}

const amortization = (option) => {}

const financeExpense = (eventLabel, hashedCpf) => {
  const ga = {
    label: `BTN:Simulador:${eventLabel}`,
    action: "Simulador",
    hashedCpf
  }

  trackEvent({ ga })
}

const showMore = () => {}

const homeInsurance = (value) => {}

const homeInsuranceLink = () => {}

const homeInsuranceLegal = () => {}

const referral = (hashedCpf, eventLabel) => {
  const ga = {
    label: `BTN:Simulador:${eventLabel}`,
    action: "Simulador",
    hashedCpf
  }

  trackEvent({ ga })
}

const accountHolder = (hashedCpf, eventLabel) => {
  const ga = {
    label: `BTN:Simulador:${eventLabel}`,
    action: "Simulador",
    hashedCpf
  }

  trackEvent({ ga })
}

const advance = () => {
  const segment = "Credit Info Sent"

  trackEvent({
    segment
  })
}

const backHome = (hashedCpf) => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/sobre-o-financiamento",
    hashedCpf,
    implementationTeam: "imob:v01",
    detail: "btn:voltar-inicio",
    eventName: "click"
  }
  trackEvent({ ga4 })
}

const previousGA4 = () => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/sobre-o-financiamento",
    implementationTeam: "imob:v01",
    detail: "btn:voltar",
    eventName: "click"
  }

  trackEvent({ ga4 })
}

const alert = (hashedCpf, error) => {
  const ga4 = {
    customPath:
      "/credito/imob/isolado/contratacao/env-proposta/sobre-o-financiamento",
    hashedCpf,
    implementationTeam: "imob:v01",
    eventName: "alert",
    description: "erro",
    detail: error.message
      ?.toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replaceAll(/ /g, "-")
      .replaceAll(/!/g, "")
  }

  trackEvent({ ga4 })
}

export {
  pageview,
  backHome,
  advance,
  previousGA4,
  aboutCreditLines,
  aboutAmortization,
  feeType,
  amortization,
  financeExpense,
  showMore,
  homeInsurance,
  homeInsuranceLink,
  homeInsuranceLegal,
  referral,
  accountHolder,
  alert
}
