import { useEffect, useState } from "react"

import { useFlags } from "pages/Proposal/hooks/useFlags"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "../../trackings"
import { fields } from "./fields"

const useFormGroupReferral = () => {
  const { cpfData } = useProposalContext()

  const { hashedCpf } = cpfData

  const { hasReferral: hasReferralFlag } = useFlags()

  const [eventLabel, setEventLabel] = useState("")

  useEffect(() => {
    if (eventLabel === "") return
    trackers.referral(hashedCpf, eventLabel)
  }, [eventLabel])

  const handleHasReferralChange = (value) => {
    if (value === "sim") {
      setEventLabel("IndicacaoSim")
    } else {
      setEventLabel("IndicacaoNão")
    }
  }

  return {
    fields,
    hasReferralFlag,
    handleHasReferralChange
  }
}

export default useFormGroupReferral
