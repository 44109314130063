import { proposalHttpController } from "../../services"

const financingConditionsImobiContextApi = ({ setIsLoading }) => {
  const checkFinanceExpense = async ({ hasLoading = true, recaptcha }) => {
    setIsLoading(hasLoading)
    try {
      const response = await proposalHttpController({
        method: "financeExpense",
        recaptcha,
      })
      return response.message || ""
    } finally {
      setIsLoading(false)
    }
  }

  return { checkFinanceExpense }
}

export { financingConditionsImobiContextApi }
