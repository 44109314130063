import logoItauSeguradora from "images/insurance-1.png"
import logoTokioMarineSeguradora from "images/insurance-2.png"

import {
  HAS_PROPERTY_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  TIME_ACQUIRE_PROPERTY_OPTIONS
} from "../../constants/dropdownOptions"

export const fields = {
  propertyType: {
    type: "select",
    name: "property_type",
    label: "tipo do imóvel",
    placeholder: "selecione",
    options: PROPERTY_TYPE_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select"
  },
  propertyValue: {
    type: "currency",
    name: "property_value",
    label: "valor do imóvel",
    rules: {
      propertyValueMinMax: {
        RESIDENCIAL: {
          min: 97561,
          max: 90_000_000.0
        },
        COMERCIAL: {
          min: 100000,
          max: 90_000_000.0
        }
      }
    }
  },
  inputValue: {
    type: "currency",
    name: "input_value",
    label: "valor da entrada",
    rules: {
      inputValueLowerThanPropertyValue: true,
      minFinancingValue: {
        RESIDENCIAL: 30000,
        COMERCIAL: 50000
      },
      inputValue: {
        RESIDENCIAL: {
          minInputRatio: 0.1,
          maxFinancingRatio: 0.9,
          minFinancingValue: 30000
        },
        COMERCIAL: {
          minInputRatio: 0.5,
          maxFinancingRatio: 0.5,
          minFinancingValue: 50000
        }
      }
    }
  },
  birthdate: {
    type: "birthdate",
    name: "birthdate",
    label: "data de nascimento",
    sendFormat: "date",
    fetchFormat: "date",
    rules: {
      minAge: 18
    }
  },
  financingTerm: {
    type: "quantity",
    name: "financing_term",
    label: "prazo de financiamento",
    sendFormat: "month",
    fetchFormat: "year",
    rules: {
      requiredYears: true,
      maxFinancingTerm: {
        RESIDENCIAL: 35,
        COMERCIAL: 20
      },
      finacingTermWithAge: 80.5,
      financingTermByAmortizationSystem: {
        SAC: 1,
        MIX: 4
      }
    }
  },
  hasProperty: {
    type: "select",
    name: "has_property",
    label: "já possui algum imóvel para financiar?",
    options: HAS_PROPERTY_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select"
  },
  timeAcquireProperty: {
    type: "select",
    name: "time_acquire_property",
    label: "quando pensa em adquirir seu imóvel? (opcional)",
    options: TIME_ACQUIRE_PROPERTY_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select"
  },
  insurer: {
    type: "customRadio",
    name: "insurer",
    options: [
      {
        id: "insurer_itau",
        value: "ITAU",
        label: "Itaú Seguradora",
        image: logoItauSeguradora,
        imageAlt: "Logo Itaú Seguradora",
        ariaLabel: "Itaú Seguradora"
      },
      {
        id: "insurer_tokio",
        value: "TOKIO",
        label: "Tokio Marine Seguradora",
        image: logoTokioMarineSeguradora,
        imageAlt: "Logo Tokio Marine Seguradora",
        ariaLabel: "Tokio Marine Seguradora"
      }
    ]
  }
}
