import { formatters } from "@garantidos/utils"

import { fields } from "./fields"

const useFormGroupPartnerAddress = (setValue) => {
  const onCepResultCallback = (address) => {
    const setValueOptions = {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    }
    const stateFormatter =
      formatters.fetchFormatters[fields.parnterState.fetchFormat]
    setValue(fields.partnerStreet.name, address.street, setValueOptions)
    setValue(
      fields.partnerNeighborhood.name,
      address.neighborhood,
      setValueOptions
    )
    setValue(
      fields.parnterState.name,
      stateFormatter({
        value: address.uf.toUpperCase(),
        options: fields.parnterState.options
      })
    )
    setValue(fields.partnerCity.name, address.city, setValueOptions)
  }

  return {
    fields,
    onCepResultCallback
  }
}

export default useFormGroupPartnerAddress
