import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import "./style.scss"

import insuranceInfos from "./data"

const AboutInsurance = ({ handleClick }) => (
  <div className="aboutInsurance">
    <Text as="h3" className="aboutInsurance__title">
      seguro habitacional
    </Text>
    <Text as="p" className="aboutInsurance__description">
      É obrigatório por lei a contratação do seguro Habitacional quando se está
      contratando o financiamento Imobiliário. Ele é incluso automaticamente no
      seu contrato. Confira as coberturas desse seguro:
    </Text>
    {insuranceInfos.map(({ Icon, title, info }, index) => (
      <div key={index} className="aboutInsurance__itemContainer">
        <div className="aboutInsurance__itemTitleContainer">
          <div className="aboutInsurance__iconWrapper">
            <Icon aria-hidden="true" className="aboutInsurance__icon" />
          </div>
          <Text as="h4" className="aboutInsurance__itemTitle">
            {title}
          </Text>
        </div>
        <Text as="p" className="aboutInsurance__info">
          {info}
        </Text>
      </div>
    ))}
    <Text as="p" className="aboutInsurance__info">
      Na contratação do seu financiamento você pode escolher entre duas
      seguradoras, Itaú Seguros ou Tokio Marine.
    </Text>
    <div className="aboutInsurance__buttons">
      <Button
        tagName="a"
        href="https://www.itau.com.br/media/dam/m/1e96f6fc4ad7ed33/original/Seguro_Habitacional_Itau.pdf"
        target="_blank"
        variant="action"
        className="aboutInsurance__aboutButton"
      >
        Conheça as condições do Itaú Seguradora
      </Button>
      <Button
        tagName="a"
        href="https://www.itau.com.br/media/dam/m/5e591daecd95cdf/original/Seguro_Habitacional_TokioMarine.pdf"
        target="_blank"
        variant="action"
        className="aboutInsurance__aboutButton"
      >
        Conheça as condições do Tokio Marine Seguradora
      </Button>
    </div>

    <Button
      fluid
      type="button"
      onClick={handleClick}
      className="aboutInsurance__button"
    >
      Continuar
    </Button>
  </div>
)

export default AboutInsurance
