import { proposalHttpController } from "@garantidos/services"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "../../trackings"

const useLikertComponent = () => {
  const { errorHandler } = useProposalContext()
  const handleSubmitCallback = async ({ experience, description }) => {
    const nonTagsDescription = description.replace(/</g, "").replace(/>/g, "")
    trackers.likert("CliqueEnviar", "")
    trackers.likertSubmit(experience, nonTagsDescription)
    try {
      const payload = {
        likert_rate: experience,
        likert_comment: description
      }
      await proposalHttpController({ payload, method: "sendLikert" })
    } catch (error) {
      errorHandler(error)
    }
  }

  const handleExperienceCallback = ({ experience }) => {
    trackers.likert(experience, "CliqueExperiencia")
    trackers.likertGa4(experience)
  }

  return {
    handleSubmitCallback,
    handleExperienceCallback
  }
}

export default useLikertComponent
