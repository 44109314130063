import { Sender } from "@garantidos/components"

import Text from "@mobi/ds/components/Text"

import "./style.scss"

const ResultCard = ({ data, handleNextStep, isLoading, handleDetails }) => {
  const {
    feeType,
    title,
    initialInstallment,
    lastInstallment,
    yearlyEffectiveRate,
    dataTestId
  } = data

  return (
    <div className="resultCard">
      <Text as="h3" className="resultCard__title">
        {title}
      </Text>
      <div className="resultCard__valuesContainer">
        <div>
          <Text as="p" className="resultCard__label">
            primeira parcela
          </Text>
          <Text as="p" className="resultCard__value">
            {initialInstallment}
          </Text>
        </div>
        <div>
          <Text as="p" className="resultCard__label">
            última parcela
          </Text>
          <Text as="p" className="resultCard__value">
            {lastInstallment}
          </Text>
        </div>
      </div>
      <div className="resultCard__valuesContainer">
        <Text as="p" className="resultCard__label--regularSize">
          taxa de juros
        </Text>
        <Text as="p" className="resultCard__value--regularSize">
          {yearlyEffectiveRate}
        </Text>
      </div>

      <Sender
        onNext={() => handleNextStep(feeType)}
        onPrev={() => handleDetails(data)}
        data_testid={dataTestId}
        nextLabel="seguir"
        prevLabel="detalhes"
        showPrev={true}
        className="resultCard__sender"
        isLoading={isLoading}
      />
    </div>
  )
}

export default ResultCard
