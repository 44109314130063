import { Field } from "@garantidos/components"

import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"

import useFormGroupReferral from "./hooks"

import "./style.scss"

const FormGroupReferral = ({ form, isLoading }) => {
  const { fields, hasReferralFlag, handleHasReferralChange } =
    useFormGroupReferral()

  const { hasReferral, referralCode, referralPhone } = fields
  return (
    <div className="formGroupReferral">
      <Text as="h2" className="formGroupReferral__subtitle">
        indicação
      </Text>
      <Text as="p" className="formGroupReferral__info">
        Você foi indicado por alguma parceria itaú? (assessoria ou imobiliária)
      </Text>
      <div className="formGroupReferral__cardRadioGroup">
        <Field
          field={hasReferral}
          form={form}
          onInput={(event) => handleHasReferralChange(event.target.value)}
          isDisabled={isLoading}
        />
      </div>
      <If
        condition={hasReferralFlag}
        renderIf={
          <>
            <Field
              field={referralCode}
              form={form}
              maxlength="4"
              data-testid={referralCode.name}
              disabled={isLoading}
            />
            <Field
              autoComplete="off"
              field={referralPhone}
              form={form}
              data-testid={referralPhone.name}
              disabled={isLoading}
            />
          </>
        }
      />
    </div>
  )
}

export default FormGroupReferral
