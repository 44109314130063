import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import "./style.scss"

const SimulationResultHeader = ({ handleClick }) => (
  <div
    className="simulationResultHeader"
    data-aos="fade"
    data-aos-delay="100"
    data-aos-duration="500"
  >
    <Text as="h2" className="simulationResultHeader__title">
      sua simulação
    </Text>
    <Button
      variant="action"
      className="simulationResultHeader__button"
      onClick={() => handleClick()}
    >
      editar
    </Button>
  </div>
)

export default SimulationResultHeader
