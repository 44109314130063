const getStepInfo = (apiStepInfo, stepsData) => {
  const { apiSteps } = stepsData
  return {
    currentFlow: apiStepInfo.flow || "default",
    currentStep: apiSteps[apiStepInfo.current_step]?.name || "",
    previousStep: apiSteps[apiStepInfo.previous_step]?.name || "",
    nextStep: apiSteps[apiStepInfo.next_step]?.name || "",
  }
}

const apiStepInfo = (stepInfo, stepsData) => {
  const { steps } = stepsData
  return {
    flow: stepInfo.currentFlow,
    current_step: steps[stepInfo.currentStep].name,
    previous_step: steps[stepInfo.previousStep]?.name,
    next_step: steps[stepInfo.nextStep]?.name,
  }
}

const getFallbackStepInfo = (stepInfo, stepsData) => {
  const stepKeys = Object.keys(stepsData.steps)

  const fallBackStepInfo = {
    currentFlow: "default",
    currentStep: "softlead",
    previousStep: "softlead",
    nextStep: "simulation",
  }

  const { currentStep } = stepInfo

  if (!currentStep) return fallBackStepInfo
  fallBackStepInfo.currentStep = currentStep
  const i = stepKeys.findIndex((key) => key === currentStep)
  const fbPreviousStep = i === 0 ? currentStep : stepKeys[i - 1]
  const fbNextStep = i === stepKeys.length - 1 ? currentStep : stepKeys[i + 1]

  fallBackStepInfo.previousStep = fbPreviousStep
  fallBackStepInfo.nextStep = fbNextStep

  return fallBackStepInfo
}

export { getStepInfo, apiStepInfo, getFallbackStepInfo }
