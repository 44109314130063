import { Field } from "@garantidos/components"

import Text from "@mobi/ds/components/Text"

import useFormGroupAccountHolder from "./hooks"

import "./style.scss"

const FormGroupAccountHolder = ({ form, isLoading }) => {
  const { fields, handleAccountHolderChange } = useFormGroupAccountHolder()

  const { isAccountHolder } = fields

  return (
    <div className="formGroupAccountHolder">
      <Text as="h2" className="formGroupAccountHolder__subtitle">
        você já possui conta no Itaú?
      </Text>
      <div className="formGroupAccountHolder__cardRadioGroup">
        <Field
          field={isAccountHolder}
          form={form}
          onInput={(event) => handleAccountHolderChange(event.target.value)}
          isDisabled={isLoading}
        />
      </div>
    </div>
  )
}

export default FormGroupAccountHolder
