import { objectToList } from "@garantidos/utils"

import Address from "./Address"
import FinancingConditions from "./FinancingConditions"
import Finished from "./Finished"
import Lead from "./Lead"
import RedirectApp from "./RedirectApp"
import Rejection from "./Rejection"
import Simulation from "./Simulation"
import SimulationResult from "./SimulationResult"
import Sms from "./Sms"
import Softlead from "./Softlead"

const steps = {
  softlead: {
    name: "softlead-simulation",
    component: Softlead,
    stepInfo: {
      title: "dados pessoais",
      index: 1,
      max: 2
    }
  },
  rejection: {
    name: "rejection",
    component: Rejection,
    title: "Erro | Financiamento Imobiliario | Itaú",
    isSplitted: false
  },
  simulation: {
    name: "simulation",
    component: Simulation,
    stepInfo: {
      title: "simulador",
      index: 2,
      max: 2
    }
  },
  simulationResult: {
    name: "simulation-result",
    component: SimulationResult,
    isSplitted: false
  },
  lead: {
    name: "proposal-lead",
    component: Lead,
    stepInfo: {
      title: "dados complementares",
      index: 1,
      max: 4
    }
  },
  address: {
    name: "proposal-lead-address",
    component: Address,
    stepInfo: {
      title: "endereço",
      index: 2,
      max: 4
    }
  },
  financingConditions: {
    name: "proposal-financing-conditions",
    component: FinancingConditions,
    stepInfo: {
      title: "financiamento",
      index: 3,
      max: 4
    }
  },
  sms: {
    name: "sms-confirmation",
    component: Sms,
    stepInfo: {
      title: "confirmação de sms",
      index: 4,
      max: 4
    }
  },
  redirectApp: {
    name: "redirect-app",
    component: RedirectApp,
    isSplitted: false
  },
  finished: {
    name: "proposal-finished",
    component: Finished,
    isSplitted: false
  }
}

const apiSteps = Object.fromEntries(
  objectToList(steps).map((step) => [
    step.value.name,
    { name: step.key, component: step.value.component }
  ])
)

export { apiSteps, steps }
