import { formatters } from "@garantidos/utils"

import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"
import IconCheck from "@mobi/ds/static/icons/outlined/check.svg"

import "./style.scss"

const FinishedMainTitle = ({ proposalIntegrationNumber = null }) => {
  const formattedProposalIntegrationNumber = formatters.formatProposalNumber(
    proposalIntegrationNumber
  )

  return (
    <div
      className="finishedMainTitle"
      data-aos="fade"
      data-aos-delay="100"
      data-aos-duration="800"
    >
      <IconCheck className="finishedMainTitle__icon" aria-hidden="true" />
      <If
        condition={formattedProposalIntegrationNumber}
        renderIf={
          <Text as="h1" className="finishedMainTitle__text">
            Sua proposta de nº
            {` ${formattedProposalIntegrationNumber} `}
            foi enviada com sucesso!
          </Text>
        }
        renderElse={
          <Text as="h1" className="finishedMainTitle__text">
            sua proposta está sendo processada, em breve você recebera um email
            com o ID da sua proposta!
          </Text>
        }
      />
      <If
        condition={formattedProposalIntegrationNumber}
        renderIf={
          <Text as="p" className="finishedMainTitle__subtitle">
            Enviaremos para você um e-mail com o retorno da análise de crédito
            em até 1 hora.
          </Text>
        }
      />
    </div>
  )
}

export default FinishedMainTitle
