import { proposalHttpController } from "../services"

const smsContextApi = ({ setIsLoading }) => {
  const resendToken = async ({ hasLoading = true }) => {
    setIsLoading(hasLoading)
    try {
      const response = await proposalHttpController({
        method: "resendToken",
      })
      return response.message || ""
    } finally {
      setIsLoading(false)
    }
  }

  return { resendToken }
}

export { smsContextApi }
