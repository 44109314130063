import { Helmet } from "react-helmet"

import {
  Header,
  Splitted_v2 as Splitted,
  StepHandler
} from "@garantidos/components"
import { getSearchParamsFromUrl } from "@garantidos/utils"

import simulationBanner from "images/imobi-fluxo.png"

import AppLayout from "layout/AppLayout"

import useProposal from "./hooks"

import {
  ProposalContext,
  useProposalContext,
  useProposalDataProvider
} from "../../contexts/proposal"

// Para usar dados mockados.
// import mockData from "./mocks"
import { steps } from "./steps"

const Proposal = ({ pageContext }) => {
  const { initialStepData } = useProposal({
    pageContext
  })
  const ProposalDataProvider = useProposalDataProvider()
  const search = getSearchParamsFromUrl()

  return (
    <AppLayout title="Crédito Imobiliário | Proposta">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header home={false} prevUrl={`/${search}`} />
      <ProposalDataProvider initialStepData={initialStepData}>
        <Splitted
          image={simulationBanner}
          imageAlt="Sala de estar com uma cadeira laranja, uma poltrona laranja e uma mesa com um abajur"
          imageAriaHidden="true"
          steps={steps}
          useProposalContext={useProposalContext}
        >
          <StepHandler
            context={ProposalContext}
            steps={steps}
            retrieveProposalRecaptcha
            useLoaderComponent={false}
          />
        </Splitted>
      </ProposalDataProvider>
    </AppLayout>
  )
}

export default Proposal
