import { useEffect, useState } from "react"

import { useStepTransition } from "@garantidos/hooks"

import useMediaQuery from "@mobi/hooks/useMediaQuery"
import generateQrCode from "@mobi/utils/events/qrcode"

import { useProposalContext } from "contexts/proposal"

import * as trackers from "./trackings"

const useRedirectApp = () => {
  const hasMobileView = useMediaQuery("tablet")

  const {
    cpfData,
    isLoading,
    errorHandler,
    stepInfo,
    setIsStepVisible,
    sendProposalData,
    proposalData
  } = useProposalContext()
  const { stepNavigator } = useStepTransition(setIsStepVisible)
  const { nextStep } = stepInfo
  const { hashedCpf } = cpfData
  const { app_link } = proposalData

  useEffect(() => {
    trackers.pageview(hashedCpf)
  }, [])

  const handleRedirectApp = async () => {
    if (isLoading) return
    const payload = { redirect_app: true }
    try {
      await sendProposalData({
        payload
      })
      trackers.advanceToApp(hashedCpf)
      window.open(proposalData.app_link, "_self")
    } catch (error) {
      errorHandler(error)
      trackers.alert(hashedCpf)
    }
  }

  const handleKeepBrowser = async () => {
    if (isLoading) return
    const payload = { redirect_app: false }
    try {
      await sendProposalData({
        payload
      })
      trackers.advanceToBrowser(hashedCpf)
      stepNavigator("next", nextStep)
    } catch (error) {
      errorHandler(error)
      trackers.alert(hashedCpf)
    }
  }

  const [qrCodeBase64, setQrCodeBase64] = useState("")

  useEffect(() => {
    async function getQrCodeBase64() {
      await generateQrCode(app_link, {}, (_, base64) => {
        setQrCodeBase64(base64)
      })
    }

    if (!hasMobileView && !qrCodeBase64 && app_link) {
      getQrCodeBase64()
    }
  }, [hasMobileView, app_link])

  return {
    handleRedirectApp,
    handleKeepBrowser,
    isLoading,
    qrCodeBase64,
    hasMobileView
  }
}

export default useRedirectApp
